<template>
    <div id="section1">
        <div id="content">
            <el-form>
                <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-autocomplete v-model="SeaCodeVal" :placeholder="$t('queryParam')"
                                         prefix-icon="el-icon-help"
                                         style="width:350px;" :fetch-suggestions="querySearchCodeAsync" @select="handleSelect" @change="handleChange">
                        </el-autocomplete>
                        <el-button :title="$t('Tracing')" type="primary" @click="SelectData()">{{$t('search')}}</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <div v-for="(item,index) in options" v-bind:key="index">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <el-button type="text" style="font-size:16px;color:#f5f7fa;" @click="SelectLink(item.value)">
                                <span >{{item.label}}</span>
                            </el-button>
                        </el-col>
                    </div>
                </el-row>
                    <div class="pvhFactory">
                        <el-dialog :title="$t('selectPO')" :visible.sync="dialogTableVisible" :modal-append-to-body="false" width="90%" top="2vh">
                            <avue-crud ref="crud" :option="tableOpt" :row-style="rowStyle" :row-key="GetRowKeys" :data="potable" :page.sync="page" @selection-change="selectionChange" @current-change="currentChange">
                                <template slot="menuLeft" style="width: calc(100% - 80px);">
                                    <el-row :gutter="20" class="topFromSearch">
                                        <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                                <span>{{$t('ProductCode')}}:</span>
                                                <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                                <span>{{$t('BATCH')}}:</span>
                                                <el-input v-model="batchVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                                <span>{{$t('COLOR')}}:</span>
                                                <el-input v-model="colorVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                                <span id="venSpan">{{$t('VENDOR')}}:</span>
                                                <el-select v-model="vendorVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;" @change="venchang" clearable="true">
                                                    <el-option v-for="item in this.vendorData"
                                                               :key="item.value"
                                                               :label="item.label"
                                                               :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                                <span>{{$t('BEINGTIME')}}:</span>
                                                <el-date-picker class="dataicon" v-model="beginTimeVal"
                                                                type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                                format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                                </el-date-picker>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                                <span>{{$t('ENDTIME')}}:</span>
                                                <el-date-picker class="dataicon" v-model="endTimeVal"
                                                                type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                                format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                                </el-date-picker>
                                            </el-col>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top:10px;">
                                            <el-button class="moreBtn" size="small" icon="iconfont icon-24px" circle @click="moreFrom" style="margin-right:0;"></el-button>
                                            <el-button type="primary" size="small" plain @click="searchFrom">{{$t('SEARCH')}}</el-button>
                                        </el-col>
                                    </el-row>
                                </template>
                                <template slot="header">
                                    <el-button type="primary" @click="submitSelectData()">{{$t('Yes')}}</el-button>
                                </template>
                                <template slot="tip">
                                </template>
                            </avue-crud>
                        </el-dialog>

                    </div>
            </el-form>
        </div>
    </div>
</template>

<style>
    .el-dialog__wrapper {
        transition-duration: 0.3s;
    }

    #section1 {
        width: 100%;
        height: 100%;
        background: url("/img/TracingSearchBG.jpg") center center no-repeat;
        background-size: cover;
        position: relative;
    }

    #content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    #section1::before {
        content: '';
        display: block;
        position: absolute;
        background-color: #000;
        opacity: 0.5;
        width: 100%;
        height: 100%;
    }

</style>
<script>
    import { PoAndDetailList } from "@/api/purchaseOrd";
    import { TracingDropDownList, TracingStringList } from "@/api/unified";
    export default {
        data() {
            return {
                codeVal: "", batchVal: "", colorVal: "", beginTimeVal: "", endTimeVal: "", vendorVal: { ids: [], }, vendorData: [],
                no_SeaCodeVal: 'no', selectId: '', SeaCodeVal: '', dialogTableVisible: false, //当前查询值
                Coderestaurants: [], Codestate: '', Codetimeout: null,
                potable: [], codeslist: [], selectedData: [],//采购商
                link0: '', link1: '', link2: '', link3: '', link4: '', link5: '',
                options: [],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                tableOpt: {
                    page: true,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: true,
                    addBtn: false,
                    addRowBtn: false,
                    cellBtn: false,
                    index: true,
                    indexLabel: ' ',
                    border: true,
                    align: "center",
                    menu: false,
                    emptyText: this.$t('No Data'),
                    selection: true,
                    reserveSelection: true,
                    tip: false,
                    column: [
                        {
                            label: this.$t('DATE'),
                            prop: 'orderDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: this.$t('VENDOR'),
                            prop: 'vendorCorporationId',
                        },
                        {
                            label: this.$t('ProductCode'),
                            prop: 'code',
                        },
                        {
                            label: this.$t('Product Name'),
                            prop: 'productName',
                        },
                        {
                            label: this.$t('BATCH'),
                            prop: 'batch',
                        },
                        {
                            label: this.$t('COLOR'),
                            prop: 'color',
                        },
                        {
                            label: this.$t('PO'),
                            prop: 'po',
                        },
                        {
                            label: this.$t('UNIT'),
                            prop: 'unitId',
                        },
                        {
                            label: this.$t('OrderNumber'),
                            prop: 'number',
                        }
                    ]
                }
            }
        },
        created() {
            this.init()
        },
        watch: {
            $route() {
                this.init()
            }
        },
        mounted() {
            this.Coderestaurants = this.getCodeList();//数据源
        },
        methods: {
            init() {
                TracingDropDownList('VendorList').then(res => {
                    this.vendorData = res.data;
                });
                TracingDropDownList('SearchLinkList').then(res => {
                    this.options = res.data;
                });
            },
            //设置表头行的样式
            tableHeaderColor() {
                return 'background-color:#838bb3!important;padding: 0!important;'

            },//表格行央视
            rowStyle({ row, column, rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
            moreFrom() {
                let fromHei = document.querySelector(".fromOutDiv").clientHeight;
                if (fromHei > 60) {
                    document.querySelector(".fromOutDiv").style.cssText = "height:60px;";
                } else {
                    document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
                }
            },  //点击更多按钮
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                } else {
                    if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                        obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    } else {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                    }

                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.vendorVal.ids.length === 0) {
                        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                    }
                }
            },
            venchang() {
                if (this.vendorVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#409EFF;";
                } else {
                    document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#8CA0B3;";
                }
            },//点击更多按钮
            searchFrom() {
                this.no_SeaCodeVal = "yes";
                this.getPurOrdList();
            },//查询
            getCodeList() {
                this.Codeslist = [];
                TracingStringList("ALLPurchaseOrderInfo").then(res => {
                       for (var i = 0; i < res.data.length; i++) {
                        this.Codeslist.push({
                            value: res.data[i]
                        })
                    }
                })
                return this.Codeslist;
            },
            querySearchCodeAsync(queryString, cb) {
                var Coderestaurants = this.Coderestaurants;
                var results = queryString ? Coderestaurants.filter(this.createStateFilterCode(queryString)) : Coderestaurants;
                clearTimeout(this.Codetimeout);
                this.Codetimeout = setTimeout(() => {
                    cb(results.slice(0,10));
                }, 1000 * Math.random());
            },
            createStateFilterCode(queryString) {
                return (Codestate) => {
                    return (Codestate.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleChange() {
                this.getPurOrdList();
            },
            handleSelect() {
                this.getPurOrdList();
            },//选中批号
            async getPurOrdList() {
                await PoAndDetailList(this.page.currentPage, this.page.pageSize, this.vendorVal.ids.toString(), this.codeVal, this.batchVal, this.colorVal, this.no_SeaCodeVal, this.SeaCodeVal, this.beginTimeVal, this.endTimeVal,).then(res => {
                    var stockListInfo = res.data.OrderStocks;
                    if (stockListInfo != null) {
                        this.potable = [];
                        for (var i = 0; i < stockListInfo.length; i++) {
                            this.potable.push({
                                id: stockListInfo[i].POInfoDetail.Id,
                                poDetailId: stockListInfo[i].POInfoDetail.Id,
                                purchaseOrderId: stockListInfo[i].POInfoDetail.PurchaseOrderId,
                                productName: stockListInfo[i].POInfoDetail.ProductName,
                                orderDate: stockListInfo[i].POInfo.OrderDate == '' ? '' : stockListInfo[i].POInfo.OrderDate.substr(0, 10),
                                po: stockListInfo[i].POInfo.Po,
                                vendorCorporationId: stockListInfo[i].POInfo.VendorCorporationId,
                                batch: stockListInfo[i].POInfoDetail.Batch,
                                color: stockListInfo[i].POInfoDetail.Color,
                                code: stockListInfo[i].POInfoDetail.Code,
                                status: stockListInfo[i].POInfoDetail.Status,
                                unitId: stockListInfo[i].POInfoDetail.UnitName,
                                number: stockListInfo[i].POInfoDetail.Number,
                            })
                        }
                    }
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                    //只有一条数据
                    if (this.potable.length == 1) {
                        this.selectId = this.potable[0].poDetailId;
                        this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: this.selectId } })
                    }
                    else {
                        this.dialogTableVisible = true;
                    }

                }).catch(erro => {
                    console.log(erro)
                })
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getPurOrdList();
            },
            GetRowKeys(row) {
                return row.Id;
            },
            selectionChange(list) {
                if (list.length > 1) {
                    this.$refs.crud.toggleSelection();
                    this.$message({
                        message: this.$t('OnlyCheckedOne'),
                        type: "warning"
                    });
                }
                else { this.selectedData = list; }
            },//改变项值
            submitSelectData() {
                if (this.selectedData.length > 0) {
                    for (var i = 0; i < this.selectedData.length; i++) {
                        this.selectId = this.selectedData[i].poDetailId;
                        this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: this.selectId } })
                    }
                }
                this.dialogTableVisible = false;
            },
            SelectData() {
                this.no_SeaCodeVal = "no";
                this.getPurOrdList();
            },
            SelectLink(e) {
                this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: e } })
            },
        },
    }
</script>