var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "home_container" } },
    [
      _c(
        "el-row",
        {
          staticClass: "item_row",
          staticStyle: { width: "100%" },
          attrs: { gutter: 20 }
        },
        [
          _c("el-col", { attrs: { span: 13 } }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" } },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/listArray/pepoleChange" } } },
                      [
                        _c("el-link", { attrs: { target: "_blank" } }, [
                          _vm._v("人员")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  { attrs: { type: "success" } },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/device/devFile" } } },
                      [
                        _c(
                          "el-link",
                          { attrs: { type: "primary", target: "_blank" } },
                          [_vm._v("机器")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  { attrs: { type: "warning" } },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            path: "/productionManagement/additionToMachine"
                          }
                        }
                      },
                      [
                        _c(
                          "el-link",
                          { attrs: { type: "primary", target: "_blank" } },
                          [_vm._v("运转")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  { attrs: { type: "info" } },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/wkln/wklnChange" } } },
                      [
                        _c(
                          "el-link",
                          { attrs: { type: "primary", target: "_blank" } },
                          [_vm._v("工艺")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  { attrs: { type: "danger" } },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/wkln/wklnChange" } } },
                      [
                        _c(
                          "el-link",
                          { attrs: { type: "primary", target: "_blank" } },
                          [_vm._v("环境")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "last" },
                  [
                    _c(
                      "el-button",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { path: "/wkln/wklnChange" } } },
                          [
                            _c(
                              "el-link",
                              { attrs: { type: "primary", target: "_blank" } },
                              [_vm._v("检测")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("el-col", { attrs: { span: 11 } }, [
            _c("div", { staticClass: "kanban" }, [
              _c(
                "div",
                { staticClass: "num" },
                [
                  _c(
                    "el-row",
                    { staticClass: "content" },
                    [
                      _c("el-col", { attrs: { span: 7 } }, [
                        _c("img", {
                          attrs: { src: "/img/button/机台.png", alt: "" }
                        })
                      ]),
                      _c("el-col", { attrs: { span: 17 } }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n                  机台机数\n                "
                          )
                        ]),
                        _c("div", { staticClass: "numb" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.machineList.count) +
                              "\n                "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "num" },
                [
                  _c(
                    "el-row",
                    { staticClass: "content" },
                    [
                      _c("el-col", { attrs: { span: 7 } }, [
                        _c("img", {
                          attrs: { src: "/img/button/当班.png", alt: "" }
                        })
                      ]),
                      _c("el-col", { attrs: { span: 17 } }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n                  当班人员\n                "
                          )
                        ]),
                        _c("div", { staticClass: "numb" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.onDutyPersonnel) +
                              "\n                "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "num" },
                [
                  _c(
                    "el-row",
                    { staticClass: "content" },
                    [
                      _c("el-col", { attrs: { span: 7 } }, [
                        _c("img", {
                          attrs: { src: "/img/button/在纺.png", alt: "" }
                        })
                      ]),
                      _c("el-col", { attrs: { span: 17 } }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n                  在纺品种\n                "
                          )
                        ]),
                        _c("div", { staticClass: "numb" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.zfpzNum) +
                              "\n                "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "item_row",
          staticStyle: { width: "100%" },
          attrs: { gutter: 20 }
        },
        [
          _c("el-col", { attrs: { span: 13 } }, [
            _c("div", { staticClass: "public" }, [
              _c("img", { attrs: { src: "/img/button/角标.png", alt: "" } }),
              _c("span", { staticClass: "tit" }, [_vm._v("机台设备")])
            ]),
            _c("div", { staticClass: "charts progress" }, [
              _c(
                "div",
                { staticClass: "item_ca" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n              运行中\n            ")
                  ]),
                  _c("div", { staticClass: "nub" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.machineList.turnOnInteger) +
                        "\n            "
                    )
                  ]),
                  !isNaN(parseInt(_vm.machineList.turnOnInteger))
                    ? _c("el-progress", {
                        attrs: {
                          type: "dashboard",
                          percentage: Number(
                            (
                              (_vm.machineList.turnOnInteger /
                                _vm.machineList.count) *
                              100
                            ).toFixed(1)
                          ),
                          color: "#00D643"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item_ca" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n              停机中\n            ")
                  ]),
                  _c("div", { staticClass: "nub" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.machineList.downInteger) +
                        "\n            "
                    )
                  ]),
                  !isNaN(parseInt(_vm.machineList.downInteger))
                    ? _c("el-progress", {
                        attrs: {
                          type: "dashboard",
                          percentage: Number(
                            (
                              (_vm.machineList.downInteger /
                                _vm.machineList.count) *
                              100
                            ).toFixed(1)
                          ),
                          color: "#FFAA00"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item_ca" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n              离线中\n            ")
                  ]),
                  _c("div", { staticClass: "nub" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.machineList.offlineInteger) +
                        "\n            "
                    )
                  ]),
                  !isNaN(parseInt(_vm.machineList.offlineInteger))
                    ? _c("el-progress", {
                        attrs: {
                          type: "dashboard",
                          percentage: Number(
                            (
                              (_vm.machineList.offlineInteger /
                                _vm.machineList.count) *
                              100
                            ).toFixed(1)
                          ),
                          color: "#FF002B"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item_ca" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n              开台率\n            ")
                  ]),
                  _c("div", { staticClass: "nub" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.machineList.alreadyInteger) +
                        "\n            "
                    )
                  ]),
                  !isNaN(parseInt(_vm.machineList.alreadyInteger))
                    ? _c("el-progress", {
                        attrs: {
                          type: "dashboard",
                          percentage: Number(
                            (
                              (_vm.machineList.alreadyInteger /
                                _vm.machineList.count) *
                              100
                            ).toFixed(1)
                          ),
                          color: "#00D4D6"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item_ca" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n              待开台率\n            ")
                  ]),
                  _c("div", { staticClass: "nub" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.machineList.waitInteger) +
                        "\n            "
                    )
                  ]),
                  !isNaN(parseInt(_vm.machineList.waitInteger))
                    ? _c("el-progress", {
                        attrs: {
                          type: "dashboard",
                          percentage: Number(
                            (
                              (_vm.machineList.waitInteger /
                                _vm.machineList.count) *
                              100
                            ).toFixed(1)
                          ),
                          color: "#3F74FB"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item_ca" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("\n              故障率\n            ")
                  ]),
                  _c("div", { staticClass: "nub" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.machineList.malfunctionInteger) +
                        "\n            "
                    )
                  ]),
                  !isNaN(parseInt(_vm.machineList.malfunctionInteger))
                    ? _c("el-progress", {
                        attrs: {
                          type: "dashboard",
                          percentage: Number(
                            (
                              (_vm.machineList.malfunctionInteger /
                                _vm.machineList.count) *
                              100
                            ).toFixed(1)
                          ),
                          color: "#FF7E7E"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _c("el-col", { attrs: { span: 7 } }, [
            _c("div", { staticClass: "public" }, [
              _c("img", { attrs: { src: "/img/button/角标.png", alt: "" } }),
              _c("span", { staticClass: "tit" }, [_vm._v("生产效率")])
            ]),
            _c("div", { staticClass: "charts progress" }, [
              _c(
                "div",
                { staticClass: "item_ca" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("实际效率")]),
                  _c("el-progress", {
                    attrs: {
                      type: "dashboard",
                      percentage: _vm.actualEfficiency,
                      color: "#FFAA00"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item_ca" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("理论效率")]),
                  _c("el-progress", {
                    staticClass: "green",
                    attrs: {
                      type: "dashboard",
                      percentage: _vm.theoryEfficiency,
                      color: "#00D643"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "left_right" }, [
              _c("div", { staticClass: "date" }, [
                _c("img", { attrs: { src: "/img/button/日历.png", alt: "" } }),
                _c("span", [_vm._v(_vm._s(_vm.nowDate))])
              ]),
              _c("div", { staticClass: "group_right" }, [
                _vm._v(
                  "\n            当前班组: " +
                    _vm._s(_vm.teamName) +
                    "\n          "
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "item_row",
          staticStyle: { width: "100%" },
          attrs: { gutter: 20 }
        },
        [
          _c("el-col", { attrs: { span: 13 } }, [
            _c("div", { staticClass: "public" }, [
              _c("img", { attrs: { src: "/img/button/角标.png", alt: "" } }),
              _c(
                "span",
                { staticClass: "tit", staticStyle: { width: "5.75rem" } },
                [_vm._v("机台检测")]
              ),
              _c(
                "div",
                { staticClass: "select_container" },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex", justify: "center", gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择设备机型",
                                size: "small"
                              },
                              on: { change: _vm.jtChange },
                              model: {
                                value: _vm.jkParams.machineModelId,
                                callback: function($$v) {
                                  _vm.$set(_vm.jkParams, "machineModelId", $$v)
                                },
                                expression: "jkParams.machineModelId"
                              }
                            },
                            _vm._l(_vm.jkoptions, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择设备编号",
                                size: "small"
                              },
                              on: { change: _vm.jtChange },
                              model: {
                                value: _vm.jkParams.code,
                                callback: function($$v) {
                                  _vm.$set(_vm.jkParams, "code", $$v)
                                },
                                expression: "jkParams.code"
                              }
                            },
                            _vm._l(_vm.bhoptions, function(item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.code, value: item.code }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "charts container" }, [
              _c("div", {
                staticStyle: { width: "55rem", height: "18.75rem" },
                attrs: { id: "prodCharts" }
              })
            ])
          ]),
          _c("el-col", { attrs: { span: 11 } }, [
            _c("div", { staticClass: "public" }, [
              _c("img", { attrs: { src: "/img/button/角标.png", alt: "" } }),
              _c("span", { staticClass: "tit" }, [_vm._v("生产产量")])
            ]),
            _c("div", { staticClass: "charts container" }, [
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: { change: _vm.clChange },
                      model: {
                        value: _vm.btnType,
                        callback: function($$v) {
                          _vm.btnType = $$v
                        },
                        expression: "btnType"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("当日:12吨")
                      ]),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("当月:80吨")
                      ]),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("当年:800吨")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", {
                staticStyle: { width: "44.875rem", height: "18rem" },
                attrs: { id: "output" }
              })
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "item_row",
          staticStyle: { width: "100%" },
          attrs: { gutter: 20 }
        },
        [
          _c(
            "el-col",
            { attrs: { span: 13 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "public",
                        staticStyle: { display: "flex" }
                      },
                      [
                        _c("img", {
                          attrs: { src: "/img/button/角标.png", alt: "" }
                        }),
                        _c("span", { staticClass: "tit" }, [
                          _vm._v("昨日人员产量排名")
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "line-height": "2.5rem",
                              "margin-left": "0.625rem",
                              "font-size": "0.9375rem"
                            }
                          },
                          [
                            _vm._v(
                              "(" +
                                _vm._s(_vm.flag == 1 ? "正序" : "倒序") +
                                ")"
                            )
                          ]
                        )
                      ]
                    ),
                    _c("div", { staticClass: "charts container" }, [
                      _c("div", {
                        staticStyle: { width: "26.25rem", height: "17.5rem" },
                        attrs: { id: "average" }
                      })
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "public",
                        staticStyle: { display: "flex" }
                      },
                      [
                        _c("img", {
                          attrs: { src: "/img/button/角标.png", alt: "" }
                        }),
                        _c("span", { staticClass: "tit" }, [
                          _vm._v("昨日班组产量排名")
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "line-height": "2.5rem",
                              "margin-left": "0.625rem",
                              "font-size": "0.9375rem"
                            }
                          },
                          [
                            _vm._v(
                              "(" +
                                _vm._s(_vm.flag == 1 ? "正序" : "倒序") +
                                ")"
                            )
                          ]
                        )
                      ]
                    ),
                    _c("div", { staticClass: "charts container" }, [
                      _c("div", {
                        staticStyle: { width: "26.25rem", height: "17.5rem" },
                        attrs: { id: "groupCharts" }
                      })
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("el-col", { attrs: { span: 11 } }, [
            _c("div", { staticClass: "public" }, [
              _c("img", { attrs: { src: "/img/button/角标.png", alt: "" } }),
              _c("span", { staticClass: "tit" }, [_vm._v("生产能耗")])
            ]),
            _c("div", { staticClass: "charts container" }, [
              _c(
                "div",
                { staticClass: "btn" },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { fill: "#483DFF", size: "medium" },
                      on: { change: _vm.nhChange },
                      model: {
                        value: _vm.btnType2,
                        callback: function($$v) {
                          _vm.btnType2 = $$v
                        },
                        expression: "btnType2"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("当日:100度")
                      ]),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("当月:2800度")
                      ]),
                      _c("el-radio-button", { attrs: { label: "3" } }, [
                        _vm._v("当年:31100度")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", {
                staticStyle: { width: "44.875rem", height: "18rem" },
                attrs: { id: "energy" }
              })
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }