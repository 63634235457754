<template>
    <div id="home_container">
        <el-row class="item_row " :gutter="20" style="width: 100%;" id="topTit">
            <el-col :span="6" class="bgtl">
                <p>{{nowDate}}&nbsp;&nbsp;&nbsp;{{bcName}}&nbsp;&nbsp;&nbsp;{{teamName}}</p>
            </el-col>
            <el-col :span="12" class="bgtc">
                <p style="text-align:center;font-size:2rem">数字化生产管理系统</p>
            </el-col>
            <el-col :span="6" class="bgtr">
                <div style="width:100px;">
                    <p>{{cjName}}&nbsp;&nbsp;&nbsp;{{precessName}}</p>
                </div>
                <p style="text-align:right;">MES 系统V1.2</p>
            </el-col>
        </el-row>
        <el-row class="item_row " :gutter="20" style="width: 100%;" id="topSel">
            <div style="align-items: center; display: flex; justify-content: space-between;">
                <el-col :span="18" style="text-align:left;display:flex;align-items:center;">
                    <div class="date" style="margin-right:15px;">
                        <img style="width:20px;margin-right:10px;vertical-align: -4px;margin-top: 2px;" src="/img/button/日历.png" alt="">
                        <span>{{nowDate}}</span>
                    </div>
                    <div class="group_right" style="margin-right:15px;">
                        当前班次: {{bcName}}
                    </div>
                    <div class="group_right">
                        当前班组: {{teamName}}
                    </div>
                </el-col>
                <el-col :span="6" style="text-align:right;display:flex;align-items:center;">
                    <el-select v-model="cjvalue" placeholder="请选择"  @change="cjsel" style="margin:10px;">
                        <el-option v-for="item in cjlist"
                                   :key="item.name"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-select v-model="precessvalue" placeholder="请选择" @change="precesssel">
                        <el-option v-for="item in precesslist"
                                   :key="item.code"
                                   :label="item.name"
                                   :value="item.code">
                        </el-option>
                    </el-select>
                </el-col>
            </div>
        </el-row>
        <el-row class="item_row" :gutter="20" style="width: 100%;">
         
            <el-col :span="5">
                <div class="topDiv bdDiv">
                    <div class="g">
                        <p style="margin-bottom: 9px;">开台数量</p>
                        <p>{{mbDataList.openCount|| '0'}}</p>
                    </div>
                    <div class="r">
                        <p style="margin-bottom: 9px;">品种数量</p>
                        <p>{{mbDataList.productCount|| '0'}}</p>
                    </div>
                    <div class="b">
                        <p style="margin-bottom: 9px;">落纱次数</p>
                        <p>{{mbDataList.doffingNumber|| '0'}}</p>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="topDiv bdDiv">
                    <div style="width:40px;text-align:center;">
                        <p style="margin-bottom:5px;">&nbsp;</p>
                        <p style="margin-bottom:5px;">当班</p>
                        <p>前班</p>
                    </div>
                    <div style="display:flex;justify-content:space-between;align-content:center;width:calc(100% - 40px);text-align:center;">
                        <div>
                            <p style="margin-bottom:5px;">总产量(kg)</p>
                            <p style="margin-bottom:5px;">{{mbDataList.departmentAddYield|| '0'}}</p>
                            <p>{{oddmbDataList.totalYield|| '0'}}</p>
                        </div>
                        <div>
                            <p style="margin-bottom:5px;">盈亏产量(kg)</p>
                            <p style="margin-bottom:5px;">{{mbDataList.lossAddEnergy|| '0'}}</p>
                            <p>{{oddmbDataList.lossYield|| '0'}}</p>
                        </div>
                        <div>
                            <p style="margin-bottom:5px;">计划产量(kg)</p>
                            <p style="margin-bottom:5px;">{{mbDataList.totalDayYield|| '0'}}</p>
                            <p>{{oddmbDataList.planYield|| '0'}}</p>
                        </div>
                        <div>
                            <p style="margin-bottom:5px;">总能耗(kwh)</p>
                            <p style="margin-bottom:5px;">{{mbDataList.departmentAddEnergy|| '0'}}</p>
                            <p>{{oddmbDataList.totalEnergyConsumption|| '0'}}</p>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="5">
                <div class="topDiv bdDiv">
                    <div style="width:100%;display:flex;justify-content:space-between;">
                        <div>
                            <p style="margin-bottom:5px;" class="g">在纺台数</p>
                            <p style="margin-bottom:5px;"><span class="g">{{mbDataList.openCount|| '0'}}</span></p>
                        </div>
                        <div>
                            <p style="margin-bottom:5px;" class="b">落纱台数</p>
                            <p style="margin-bottom:5px;"><span class="b">{{mbDataList.doffingCount|| '0'}}</span></p>
                        </div>
                        <div>
                            <p style="margin-bottom:5px;" class="r">停机台数</p>
                            <p style="margin-bottom:5px;"><span class="r">{{mbDataList.offCount|| '0'}}</span></p>
                        </div>
                        <!--<p style="margin-bottom: 9px;">在纺/落纱/停机台数</p>
                        <p><span class="b">{{mbDataList.openCount|| '0'}}</span> / {{mbDataList.doffingCount|| '0'}} / <span class="r">{{mbDataList.offCount|| '0'}}</span></p>-->
                    </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="topDiv bdDiv">
                    <div class="r">
                        <p style="margin-bottom: 9px;">平均车速(rpm/min)</p>
                        <p>{{mbDataList.avgSpeed|| '0'}}</p>
                    </div>
                    <div class="b">
                        <p style="margin-bottom: 9px;">平均吨纱能耗(kwh)</p>
                        <p>{{mbDataList.avgAddEnergy|| '0'}}</p>
                    </div>
                    <div class="g">
                        <p style="margin-bottom: 9px;">综合效率</p>
                        <p>{{mbDataList.totalEffectiveness|| '0'}}%</p>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!--<el-row class="item_row" :gutter="20" style="width: 100%;">
            <el-col :span="24">
                <div class="twoOutDiv bdDiv">
                    <div class="twoDiv">
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                        <div class="text"><a>这是一条错误信息</a></div>
                    </div>
                </div>
            </el-col>
        </el-row>-->
        <el-row class="item_row" :gutter="20" style="width: 100%;">
            <el-col :span="8">
                <div class="bdDiv">
                    <div class="public">
                        <img src="/img/button/角标.png" alt="">
                        <span class="tit">品种支数吨纱能耗 kwh/t</span>
                    </div>
                    <div class="charts container">
                        <div id="banzhi" style="width: 100%;height:18.75rem;"></div>
                    </div>
                </div>
            </el-col>

            <el-col :span="8">
                <div class="bdDiv">
                    <div class="public">
                        <img src="/img/button/角标.png" alt="">
                        <span class="tit">班次能耗及吨纱耗电</span>
                    </div>
                    <div class="charts container">
                        <div id="pinzhong" style="width: 100%;height:18rem;"></div>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="bdDiv">
                    <div class="public">
                        <img src="/img/button/角标.png" alt="">
                        <span class="tit">设备效率排名 %</span>
                    </div>
                    <div class="charts container">
                        <div id="shebei" style="width: 100%;height:18rem;"></div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row class="item_row" :gutter="20" style="width: 100%;">
            <el-col :span="24">
                <div class="bdDiv">
                    <div class="public">
                        <img src="/img/button/角标.png" alt="">
                        <span class="tit">机台实时状态</span>
                    </div>
                    <div class="charts container">
                        <div id="jitai" style="width: 100%;height:18rem;"></div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { dateFormat } from '@/filters/'
    import { getData, calssList, getProd, getShop, getMachData, getOpenM, Counter,precessList} from '@/api/visualData'
export default {
  name: "wel",
  data() {
      return {
          nowDate: '',
          pzzsdata:[],
      actualEfficiency:0,
      theoryEfficiency:0,
        mbDataList: {},
        oddmbDataList: {},
        cjlist: [],
          cjvalue: "",
          precessvalue: "",
          precesslist: [],
          precessName:"",
        cjName:"",
      percentage: 73.2,
      btnType: '1',
      btnType2: '1',
      jkParams: {
        machineModelId: '',
        code:''
      },
      jkoptions: [],
      bhoptions: [],
      pxoptions: [],
      fullscreen:false,
      timer:null,
      timer2:null,
      timer3:null,
          teamName: '',
      bcName:"",
      onDutyPersonnel:0,
      flag:0 ,// 0倒数 1正序
      flag2:0 ,// 0倒数 1正序
      ryclTimer:null ,
      allTimer:null ,
      zfpzNum:0,
      nowTimer:null,
    };
  },
        computed: {},
        created() {
  },
        mounted() {
            this.getCJ();
            //this.getPrecess();
    //this.getInnt()
    //this.getCPPM() // 获取昨日人员产品排名
    //this.getBZCL() // 获取昨日班组产品排名
    //this.getCode() // 编号
    //this.getEquipmentModel() // 机型
    this.allTimer =  setInterval(()=>{
      clearInterval(this.timer)
      clearInterval(this.time2)
      clearInterval(this.timer3)
        this.getData();
        this.getpinzhong();
        this.getBZInfo();
    },600000)
    ////this.ryclTimer =  setInterval(()=>{
    //  this.getCPPM() // 获取昨日人员产品排名
    //  this.getBZCL() // 获取昨日班组产品排名
    ////},20000)
    this.nowTimer = setInterval(()=>{
      this.nowDate = dateFormat(new Date())
    },1000)
  },
  destroyed() {
    clearInterval(this.timer)
    clearInterval(this.time2)
    clearInterval(this.timer3)
    clearInterval(this.ryclTimer)
    clearInterval(this.allTimer)
    clearInterval(this.nowTimer)
  },
        methods: {
            // numFun(maxNum,s) {
            //     var that = this
            //     let numText = 0;
            //     let golb; // 为了清除requestAnimationFrame
            //     function numSlideFun() { // 数字动画
            //         numText += 1000; // 速度的计算可以为小数 。数字越大，滚动越快
            //         if (numText >= maxNum) {
            //             numText = maxNum;
            //             cancelAnimationFrame(golb);
            //         } else {
            //             golb = requestAnimationFrame(numSlideFun);
            //         }
            //         if (s == 1) {
            //             //console.log(123)
            //             that.mbDataList.openCount = numText
            //         } else if (s == 2) {
            //             that.mbDataList.productCount = numText
            //         } else if (s == 3) {
            //             that.mbDataList.doffingNumber = numText
            //         } else if (s == 4) {
            //             that.mbDataList.departmentAddYield = numText
            //         } else if (s == 5) {
            //             that.mbDataList.lossAddEnergy = numText
            //         } else if (s == 6) {
            //             that.mbDataList.totalDayYield = numText
            //         } else if (s == 7) {
            //             that.mbDataList.departmentAddEnergy = numText
            //         } else if (s == 8) {
            //             that.oddmbDataList.totalYield = numText
            //         } else if (s == 9) {
            //             that.oddmbDataList.lossYield = numText
            //         } else if (s == 10) {
            //             that.oddmbDataList.planYield = numText
            //         } else if (s == 11) {
            //             that.oddmbDataList.totalEnergyConsumption = numText
            //         } else if (s == 12) {
            //             that.mbDataList.openCount = numText
            //         } else if (s == 13) {
            //             that.mbDataList.offCount = numText
            //         } else if (s == 14) {
            //             that.mbDataList.avgSpeed = numText
            //         } else if (s == 15) {
            //             that.mbDataList.avgAddEnergy = numText
            //         } else if (s == 16) {
            //             that.mbDataList.totalEffectiveness = numText
            //         }
            //     }
            //     numSlideFun(); // 调用数字动画
            // },
            //车间
            getCJ() {
                calssList(1).then(res => {
                    this.cjlist = res.data.data;
                    this.cjvalue = this.cjlist[0].id;
                    this.cjName = this.cjlist[0].name;
                    precessList().then(res => {
                       // console.log(res, 'press')
                        console.log('res',res);
                        this.precesslist = res.data;
                        this.precessvalue = this.precesslist[0].code;
                        this.precessName = this.precesslist[0].name;
                        this.setInit();
                    })
                    //this.setInit();
                })
            },
            //工序
            getPrecess() {
                precessList().then(res => {
                    //console.log(res,'press')
                    this.precesslist = res.data;
                    this.precessvalue = this.precesslist[0].code;
                    this.precessName = this.precesslist[0].name;
                    this.setInit();
                })
            },
            cjsel(e) {
               // console.log(e)
                for (var i = 0; i < this.cjlist.length; i++) {
                    if (this.cjlist[i].id === e) {
                        this.cjName = this.cjlist[i].name;
                    }
                }
                //this.cjvalue = e;
              //  console.log(this.cjvalue)
                this.setInit();
            },
            precesssel(e) {
               // console.log(e)
                for (var i = 0; i < this.precesslist.length; i++) {
                    if (this.precesslist[i].id === e) {
                        this.precessName = this.precesslist[i].name;
                    }
                }
                //this.cjvalue = e;
               // console.log(this.precessvalue)
                this.setInit();
            },
            setInit() {
                this.getbanzhi();
                this.getpinzhong();
                this.getshebei();
                this.getjitai();
                this.getData();
                this.getBZInfo();
            },
            //获取面板数据
             getData() {
              var that = this
                 getData(this.cjvalue, this.precessvalue).then(res => {
                   that.mbDataList = res.data.data
                   console.log('==============>',this.mbDataList)
                    // this.mbDataList = res.data.data;
                   // console.log('==============>')
                  //  console.log(res)
                    for (var i in this.mbDataList) {
                        if (this.mbDataList[i].toString().indexOf(".") != -1) {
                            this.mbDataList[i] = Number(this.mbDataList[i].toFixed(0))
                        }
                    }
                    this.oddmbDataList = res.data.data.oldWorkShopDto;
                    for (var i in this.oddmbDataList) {
                        if (this.oddmbDataList[i].toString().indexOf(".") != -1) {
                            this.oddmbDataList[i] = this.oddmbDataList[i].toFixed(0)
                        }
                    }
                    //  this.numFun(res.data.data.openCount, 1);
                    //  this.numFun(res.data.data.productCount, 2);
                    //  this.numFun(res.data.data.doffingNumber, 3);
                    //  this.numFun(res.data.data.departmentAddYield, 4);
                    //  this.numFun(res.data.data.lossAddEnergy, 5);
                    //  this.numFun(res.data.data.totalDayYield, 6);
                    //  this.numFun(res.data.data.departmentAddEnergy, 7);
                    //  this.numFun(res.data.data.oldWorkShopDto.totalYield, 8);
                    //  this.numFun(res.data.data.oldWorkShopDto.lossYield, 9);
                    //  this.numFun(res.data.data.oldWorkShopDto.planYield, 10);
                    //  this.numFun(res.data.data.oldWorkShopDto.totalEnergyConsumption, 11);
                    //  this.numFun(res.data.data.openCount, 12);
                    //  this.numFun(res.data.data.offCount, 13);
                     //this.numFun(res.data.data.avgSpeed, 14);
                    //  this.numFun(res.data.data.avgAddEnergy, 15);

                    // console.log(res.data.data.totalEffectiveness)
                    //  this.numFun(res.data.data.totalEffectiveness, 16);
                })
            },
            //品种支数
            async getbanzhi() {
                var chartDom = document.getElementById('banzhi');
                var myChart = this.$echarts.init(chartDom);
                var option;
                var timer = null;
                var maxShow = 5;
                var that = this;
                getProd(this.cjvalue, this.precessvalue).then(v => {
                   console.log(v, '123')
                    that.pzzsdata = v.data.data;
                    var endV;
                    if (that.pzzsdata.length > 5) {
                        endV = 5;
                    } else {
                        endV = that.pzzsdata.length-1;
                    }
                    option = {
                        tooltip: {
                            trigger: 'axis',
                            formatter: function (a) {
                                var fVal = "";
                                fVal = a[0].axisValueLabel + "<br />" + "<div style='display:inline-block;width:10px;height:10px;background:#00B7FF;border-radius:50%;margin-right:8px;'></div>" + a[0].value;
                                return fVal;
                            },
                            axisPointer: {
                                type: 'shadow',
                                label: {
                                    backgroundColor: '#283b56'
                                }
                            }
                        },
                        xAxis: {
                            type: 'value',
                            show: false,
                        },
                        yAxis: [
                            {
                                type: 'category',
                                show: false,
                                axisLabel: {
                                    show:false,
                                    formatter: function () {
                                        return "";
                                    }
                                },
                                boundaryGap: false,
                                data: (function () {
                                    var res = [];
                                    var len = 0;
                                    if (that.pzzsdata.length > 6) {
                                        len = 5;
                                    } else {
                                        len = that.pzzsdata.length;
                                    }
                                    while (len--) {
                                        res.unshift(that.pzzsdata[len].productName);
                                    }
                                    return res;
                                })(),
                                axisLine: {
                                    show: false,
                                },
                                axisTick: {
                                    show: false
                                }
                            },
                            {
                                type: 'category',
                                position: "left",
                                boundaryGap: false,
                                data: (function () {
                                    var res = [];
                                    var len = 0;
                                    if (that.pzzsdata.length > 5) {
                                        len = 5;
                                    } else {
                                        len = that.pzzsdata.length;
                                    }
                                    while (len--) {
                                        res.unshift(that.pzzsdata[len].productSC);
                                    }
                                    return res;
                                })(),
                                axisLine: {
                                    show: false,
                                },
                                axisTick: {
                                    show: false
                                }
                            }
                        ],
                        dataZoom: [
                            {
                                yAxisIndex: 0,// 这里是从X轴的0刻度开始
                                show: false, // 是否显示滑动条，不影响使用
                                type: "inside", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
                                startValue:0, // 从头开始。
                                endValue: endV, // 一次性展示多少个。
                            },
                        ],
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            top: '10%',
                            containLabel: true
                        },
                        series: [{
                            data: (function () {
                                var res = [];
                                var len = 0;
                                if (that.pzzsdata.length > 5) {
                                    len = 5;
                                } else {
                                    len = that.pzzsdata.length;
                                }
                                while (len--) {
                                    res.unshift(that.pzzsdata[len].productYieldEnergy);
                                }
                                return res;
                            })(),
                            type: 'bar',
                            stack: 'total',
                            barWidth: '50%',
                            label: {
                                show: true,
                                position: 'insideLeft',
                                formatter: [
                                    '{people|{b}}',
                                    '{percentage|{c}}'
                                ].join(' '),
                                rich: {
                                    people: {
                                        align: 'left',
                                        width:"50%"
                                    },
                                    percentage: {
                                        align: 'right',
                                        width: "50%"
                                    }
                                }
                            },
                            itemStyle: {
                                color: "#00B7FF"
                            },
                        }]
                    };
                    myChart.setOption(option);
                    var n = 0;
                    if (that.pzzsdata.length > 2) {
                        n = 2;
                    } else {
                        n = that.pzzsdata.length-1;
                    }
                    setInterval(async function () {
                        // 每次向后滚动一个，最后一个从头开始。
                        //console.log(n,data)
                        if (n == that.pzzsdata.length - 1) {
                            var sr = [];
                            let post = () => {
                                return new Promise(resolve => {
                                    getProd(that.cjvalue, this.precessvalue).then(v => {
                                        sr = v.data.data;
                                        resolve(sr);
                                    })
                                });
                            }
                            sr =  await post(); 
                            option.dataZoom[0].endValue = 2;
                            option.dataZoom[0].startValue = 0;
                            option.series[0].data.shift();
                            option.series[0].data.push(sr[0].productYieldEnergy)
                            option.yAxis[0].data.shift();
                            option.yAxis[0].data.push(sr[0].productCode);
                            that.pzzsdata = sr;
                            n = 0;
                        }
                        else {
                         //   console.log(that.pzzsdata,2)
                            n++;
                            option.dataZoom[0].endValue = option.dataZoom[0].endValue + 1;
                            option.dataZoom[0].startValue = option.dataZoom[0].startValue + 1;
                            option.series[0].data.shift();
                            option.series[0].data.push(that.pzzsdata[n].productYieldEnergy);
                            option.yAxis[0].data.shift();
                            option.yAxis[0].data.push(that.pzzsdata[n].productCode)
                        }
                        myChart.setOption(option);
                    }, 600000);
                })
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //班次能耗
            async getpinzhong() {
                var chartDom = document.getElementById('pinzhong');
                var myChart = this.$echarts.init(chartDom);
                var option;
                getShop(this.cjvalue, this.precessvalue).then(res => {
                  //  console.log(res, 587)
                    option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#283b56'
                                }
                            }
                        },
                        legend: {
                            show: true,
                            data: ['班次能耗', '吨纱耗电'],
                            top: '5%'
                        },
                        xAxis: {
                            type: 'category',
                            data: res.data.data.map(function (item) {
                                return item.shifName
                            }),
                            splitLine: {
                                show: false,
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#ACACAC',
                                }
                            },
                            axisTick: {
                                alignWithLabel: true,
                                show: false,
                            }
                        },
                        yAxis: [
                            {
                                name:"kwh",
                                type: 'value',
                                axisTick: {
                                    show: false
                                },
                                splitLine: {
                                    show: false,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#ACACAC',
                                    }
                                },
                                min: 0,
                                max: 800,
                                maxInterval: 100
                            },
                            {
                                name:"kwh/t",
                                type: 'value',
                                axisTick: {
                                    show: false
                                },
                                splitLine: {
                                    show: false,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#ACACAC',
                                    }
                                },
                                min: 0,
                                max: 1500,
                                maxInterval: 200
                            }
                        ],
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            top: '13%',
                            containLabel: true
                        },
                        series: [
                            {
                                name: '吨纱耗电',
                                data: res.data.data.map(function (item) {
                                    return item.yieldEnergy;
                                }),
                                type: 'bar',
                                yAxisIndex: 1,
                                barWidth: '50%',
                                itemStyle: {
                                    color: "#FFB820"
                                }
                            },
                            {
                                name: '班次能耗',
                                data: res.data.data.map(function (item) {
                                    return item.totalEnergyConsumption;
                                }),
                                type: 'line',
                                yAxisIndex:0,
                                lineStyle: {
                                    color: '#06B9FF'
                                },
                                itemStyle: {
                                    color: "#06B9FF"
                                }
                            }
                        ]
                    };
                    myChart.setOption(option);
                })
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //设备效率
            async getshebei() {
                var chartDom = document.getElementById('shebei');
                var myChart = this.$echarts.init(chartDom);
                var option;
                getMachData(this.cjvalue, this.precessvalue).then(res => {
               //     console.log(res,234);
                    option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#283b56'
                                }
                            }
                        },
                        grid: [{
                            top: '13%',
                            bottom: '10%',
                            left: '15%',
                            right: '50%',
                            width: '35%'
                        }, {
                            top: '13%',
                            bottom: '10%',
                            left: '50%',
                            right: '15%',
                            width: '35%'
                        }],
                        xAxis: [
                            {
                                type: 'value',
                                min: 0,
                                max: 100,
                                interval: 50,
                                axisTick: {
                                    alignWithLabel: true,
                                    show: false,
                                },
                                splitLine: {
                                    show: false,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#ACACAC',
                                    }
                                },
                            },
                            {
                                type: 'value',
                                min: 0,
                                max: 100,
                                interval: 50,
                                gridIndex: 1,
                                inverse: true,
                                axisTick: {
                                    alignWithLabel: true,
                                    show: false,
                                },
                                splitLine: {
                                    show: false,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#ACACAC',
                                    }
                                },
                            }
                        ],
                        yAxis: [
                            {
                                name: "Top6",
                                type: 'category',
                                data: res.data.data.QIAN.map(function (item) {
                                    return item.machineCode;
                                }),
                                nameGap: 10,
                                nameTextStyle: {
                                    color: "#666"
                                },
                                splitLine: {
                                    show: false,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#ACACAC',
                                    }
                                },
                                axisTick: {
                                    show: false
                                },
                            },
                            {
                                name:"Bottom6",
                                type: 'category',
                                data: res.data.data.HOU.map(function (item) {
                                    return item.machineCode;
                                }),
                                gridIndex: 1,
                                position: 'right',
                                nameGap: 10,
                                nameTextStyle: {
                                    color: "#666"
                                },
                                splitLine: {
                                    show: false,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#ACACAC',
                                    }
                                },
                                axisTick: {
                                    show: false
                                },
                            }
                        ],
                        series: [
                            {
                                name: 'Top 6',
                                data: res.data.data.QIAN.map(function (item) {
                                    return item.machineEfficiency;
                                }),
                                type: 'bar',
                                itemStyle: {
                                    color: "#FFB820"
                                }
                            },
                            {
                                name: 'Bottom 6',
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                                data: res.data.data.HOU.map(function (item) {
                                    return item.machineEfficiency;
                                }),
                                type: 'bar',
                                lineStyle: {
                                    color: '#06B9FF'
                                }
                            }
                        ]
                    };
                    myChart.setOption(option);
                })
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //机台实时
            async getjitai() {
                var chartDom = document.getElementById('jitai');
                var myChart = this.$echarts.init(chartDom);
                var option;
                var that = this;
                getOpenM(this.cjvalue, this.precessvalue).then(v => {
                  //  console.log(v, 546)
                    var data = v.data.data, endV;
                    if (data.length > 5) {
                        endV = 5;
                    } else {
                        endV = data.length-1;
                    }
                    option = {
                        tooltip: {
                            trigger: 'axis',
                            formatter: function (a) {
                                var fVal = "";
                                fVal = a[0].axisValueLabel + "<br />" + "<div style='display:inline-block;width:10px;height:10px;background:#00B7FF;border-radius:50%;margin-right:8px;'></div>" + a[0].seriesName + ": " + a[0].data * 0.01 + " kg<br />" + "<div style='display:inline-block;width:10px;height:10px;background:#FFB820;border-radius:50%;margin-right:8px;'></div>" + a[1].seriesName + ": " + a[1].data + " rpm/min<br />" + "<div style='display:inline-block;width:10px;height:10px;background:#C8C8C8;border-radius:50%;margin-right:8px;'></div>" +a[2].seriesName + ": "+a[2].data+" kwh/t";
                                return fVal;
                            },
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#283b56'
                                }
                            }
                        },
                        legend: {
                            show: true,
                            data: ['当前班组实际产量', '锭速', '当前班组实际吨纱能耗'],
                            right: '5%',
                            top: '5%'
                        },
                        xAxis: [
                            {
                                type: 'category',
                                //data: res.data.data.map(function (item) {
                                //    return item.productName;
                                //}),
                                data: (function () {
                                    var res = [];
                                    var len = 0;
                                    if (data.length > 6) {
                                        len = 6;
                                    } else {
                                        len = data.length;
                                    }
                                    while (len--) {
                                        res.unshift(data[len].machineCode);
                                    }
                                    return res;
                                })(),
                                axisTick: {
                                    alignWithLabel: true,
                                    show: false,
                                },
                                splitLine: {
                                    show: false,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#ACACAC',
                                    }
                                },
                            }
                        ],
                        yAxis: [
                            {
                                name:"rpm/min",
                                type: 'value',
                                splitLine: {
                                    show: false,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#ACACAC',
                                    }
                                },
                                min: 0,
                                max: 14000,
                                maxInterval: 1000
                            },
                            {
                                name:"kwh/t",
                                type: 'value',
                                splitLine: {
                                    show: false,
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#ACACAC',
                                    }
                                },
                                min: 0,
                                max: 6000,
                                maxInterval: 1000
                            }
                        ],
                        dataZoom: [
                            {
                                xAxisIndex: 0,// 这里是从X轴的0刻度开始
                                show: false, // 是否显示滑动条，不影响使用
                                type: "inside", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
                                startValue: 0, // 从头开始。
                                endValue: endV, // 一次性展示多少个。
                            },
                        ],
                        grid: {
                            left: '1%',
                            right: '1%',
                            bottom: '3%',
                            top: '13%',
                            containLabel: true
                        },
                        series: [
                            {
                                name: '当前班组实际产量',
                                data: (function () {
                                    var res = [];
                                    var len = 0;
                                    if (data.length > 6) {
                                        len = 6;
                                    } else {
                                        len = data.length;
                                    }
                                    while (len--) {
                                        res.unshift(data[len].departmentAddYield);
                                    }
                                    return res;
                                })(),
                                type: 'bar',
                                yAxisIndex:0,
                                barWidth: '25%',
                                itemStyle: {
                                    color: "#00B7FF"
                                }
                            },
                            {
                                name: '锭速',
                                data: (function () {
                                    var res = [];
                                    var len = 0;
                                    if (data.length > 6) {
                                        len = 6;
                                    } else {
                                        len = data.length;
                                    }
                                    while (len--) {
                                        res.unshift(data[len].spindleSpeed);
                                    }
                                    return res;
                                })(),
                                type: 'line',
                                yAxisIndex: 0,
                                lineStyle: {
                                    color: '#FFB820'
                                },
                                itemStyle: {
                                    color: "#FFB820"
                                }
                            },
                            {
                                name: '当前班组实际吨纱能耗',
                                data: (function () {
                                    var res = [];
                                    var len = 0;
                                    if (data.length > 6) {
                                        len = 6;
                                    } else {
                                        len = data.length;
                                    }
                                    while (len--) {
                                        res.unshift(data[len].ecpty);
                                    }
                                    return res;
                                })(),
                                type: 'line',
                                yAxisIndex: 1,
                                lineStyle: {
                                    color: '#C8C8C8'
                                },
                                itemStyle: {
                                    color: "#C8C8C8"
                                }
                            }
                        ]
                    };
                    myChart.setOption(option);
                    var n = 0;
                    if (data.length > 5) {
                        n = 5;
                    } else {
                        n = data.length - 1;
                    }
                    if (data.length > 5) {
                        setInterval(async function () {
                            // 每次向后滚动一个，最后一个从头开始。
                            if (n == data.length - 1) {
                                var sr = [];
                                let post = () => {
                                    return new Promise(resolve => {
                                        getOpenM(that.cjvalue, this.precessvalue).then(v => {
                                            sr = v.data.data;
                                            resolve(sr);
                                        })
                                    });
                                }
                                sr = await post();
                                option.dataZoom[0].endValue = 5;
                                option.dataZoom[0].startValue = 0;
                                option.series[0].data.shift();
                                option.series[0].data.push(sr[0].machineYield)
                                option.series[1].data.shift();
                                option.series[1].data.push(sr[0].spindleSpeed)
                                option.series[2].data.shift();
                                option.series[2].data.push(sr[0].ecpty)
                                option.xAxis[0].data.shift();
                                option.xAxis[0].data.push(sr[0].productName);
                                n = 0;
                                data = sr;
                            }
                            else {
                                n++;
                                option.dataZoom[0].endValue = option.dataZoom[0].endValue + 1;
                                option.dataZoom[0].startValue = option.dataZoom[0].startValue + 1;
                                option.series[0].data.shift();
                                option.series[0].data.push(data[n].machineYield);
                                option.series[1].data.shift();
                                option.series[1].data.push(data[n].spindleSpeed);
                                option.series[2].data.shift();
                                option.series[2].data.push(data[n].ecpty);
                                option.xAxis[0].data.shift();
                                option.xAxis[0].data.push(data[n].productName)
                            }
                            myChart.setOption(option);
                        }, 600000);
                    } else {
                        setInterval(function () {
                            that.getjitai();
                        }, 60000*5);
                    }
                })
                window.addEventListener("resize", () => { myChart.resize(); });
            },
    //  设备机型
    async getEquipmentModel() {
      const  res = await this.request.post(this.proApis.MACHINEMODELSELECTALL)
      this.jkoptions = res.data
    },
    // 编号
    async getCode() {
      const  res = await this.request.post(this.proApis.SELECTMACHINEBYGORP,{})
      this.bhoptions = res.data.data.items
    },
    //10分钟刷新一次所有数据
            getInnt() {
      this.getStatus()
      this.getBZInfo()
      this.getDBInfo()
      this.getZFPZ()
      this.getProducingEfficiency()
      this.getMachineYield()
      this.getProductionEnergyConsumption(1)
                this.getJTJC()
                this.getBZInfo();
    },
    // 获取班组信息
    async getBZInfo(){
      const res = await this.request.get(this.proApis.SCHEDULESELECTGROUPNAMEBYTIME+'?date='+dateFormat(new Date()))
      res.data.data.forEach(item=>{
          this.teamName = item.groupName + '/';
          this.bcName = item.shiftName + '/';
      })
      //  console.log(res,'班组')
        this.teamName = this.teamName.substr(0, this.teamName.length - 1);
        this.bcName = this.bcName.substr(0, this.bcName.length - 1);
    },
    // 获取当班人数
    async getDBInfo(){
      const res = await this.request.get(this.proApis.SCHEDULEUSERSELECTNUMBER+'?belongDate='+dateFormat(new Date()))
      this.onDutyPersonnel = res.data.data
    },
    // 获取在纺品种
    async getZFPZ(){
        const res = await this.request.get(this.proApis.SPECSHEETQUERYALLPRODUCT)
     //   console.log(res,"品种")
      this.zfpzNum = res.data.data
    },
    // 获取生产效率
    async getProducingEfficiency(){
      const res = await this.request.get(this.proApis.SPECSHEETMACHINEFACTQUERYEFFICIENCY)
      //console.log(res.data.data,'生产效率')
      this.actualEfficiency = res.data.data.actual * 100 >100? 100 : res.data.data.actual * 100
      this.theoryEfficiency = res.data.data.theory * 100>100? 100 : res.data.data.theory * 100
    },
    // 机台筛选
    jtChange(){
      if(this.time2) clearInterval(this.time2)
      this.getJTJC()
    },
    // 获取根据设备状态查询数量
    async getStatus(){
      const res = await this.request.get(this.proApis.MACHINESELECTSTATUS)
      this.machineList = res.data
    },
    // 获取昨日人员产品排名
    async getCPPM(){
      if (this.flag === 1) {
        this.flag = 0
      }else {
        this.flag = 1
      }
      const res = await this.request.get(this.proApis.CORPUSERSYIELDSELECTUSERYIELD+'?flag='+this.flag)
      let xData = []
      let yData = []
      res.data.data.forEach(item => {
        xData.unshift(item.totalOutputYield)
        yData.unshift(item.userName)
      })
      this.setChartsForRY(xData,yData)
    },
    // 获取昨日班组产品排名
    async getBZCL(){
      if (this.flag2 === 1) {
        this.flag2 = 0
      }else {
        this.flag2 = 1
      }
      const res = await this.request.get(this.proApis.DEPARTMENTYIELDSELECTGROUPYIELD+'?flag='+this.flag2)
      let xData = []
      let yData = []
      res.data.data.forEach(item => {
        xData.unshift(item.totalOutputYield)
        yData.unshift(item.groupName)
      })
      this.setChartsForBZ(xData,yData)
    },
    // 获取机台检测
    async getJTJC(){
      const res = await this.request.post(this.proApis.SELECTALLBYCORPORATIONID,this.jkParams)
      if(res.data.code == '0000'){
        this.setChartsForJK(res.data.data)
      } else {
        this.setChartsForJK([])
      }

    },
    // 产量变化
    clChange(){
      this.getMachineYield(this.btnType)
    },
    // 获取生产产量
    async getMachineYield(type){
      let dayTime = dateFormat(new Date())
      let api
      if(type == 1){
        api = this.proApis.MACHINEYIELDSELECTYIELDTODAY+"?dayTime="+dayTime // 当天
      }else if( type == 2) {
        api = this.proApis.MACHINEDATEYIELDSELECTBYDATE+"?monthTime="+dayTime //当月
      }else if( type == 3) {
        api = this.proApis.MACHINEDATEYIELDSELECTBYMONTH+"?dateTime="+dayTime //当年
      }
      clearInterval(this.timer)
      const res = await this.request.get(api)
      this.setChartsForCL(res.data)
    },
    // 机台检测配置
    setChartsForJK(chartsData) {
      const myChart = this.$echarts.init(document.getElementById('prodCharts'));
      var option = {
        tooltip: {
          trigger: 'axis',
          show: true,
          axisPointer: {
            // type: 'cross',
            label: {
              backgroundColor: '#00C5D8'
            }
          },
          formatter:(params)=>{
            return `${params[0].marker}机台:${params[0].name.split('#&')[0]}<br>
                    ${params[0].marker}车速:${chartsData[params[0].name.split('#&')[1]].spindleSpeed}<br>
                    ${params[0].marker}台时产量:${chartsData[params[0].name.split('#&')[1]].decimal}<br>
                    ${params[0].marker}效率:${Number(chartsData[params[0].name.split('#&')[1]].effectiveness).toFixed(3)}`
          }

        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            axisLabel:{
              interval: 0,
              formatter:function(value){
                let ret = "";//拼接加\n返回的类目项
                let maxLength = 2;//每项显示文字个数
                let valLength = value.length;//X轴类目项的文字个数
                let rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                if (chartsData.length>10 && rowN > 1)//如果类目项的文字大于3,
                {
                  for (let i = 0; i < rowN; i++) {
                    let temp = "";//每次截取的字符串
                    let start = i * maxLength;//开始截取的位置
                    let end = start + maxLength;//结束截取的位置
                    //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                    temp = value.substring(start, end) + "\n";
                    ret += temp; //凭借最终的字符串
                  }
                  return ret.split('#&')[0];
                }
                else {
                  // console.log(value)
                  return value.split('#&')[0];
                }
              }
            },
            data: (function () {
              let now = chartsData
              let res = [];
              let len = chartsData.length>2? 2 : chartsData.length;
              while (len--) {
                res.unshift(now[len].machine)
              }
              return res;
            })()
          },
          {
            type: 'category',
            show: false,
            boundaryGap: true,
            data: (function () {
              let now = chartsData
              let res = [];
              let len = chartsData.length>2? 2 : chartsData.length;
              while (len--) {
                res.unshift(now[len].machine)
              }
              return res;
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '效率',
            boundaryGap: [0.2, 0.2]
          },
          {
            type: 'value',
            show: false,
            scale: true,
            name: '预购量',
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '机台',
            barWidth: 20,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: 'rgba(14,200,218,0.8)'},
                    {offset: 0, color: 'rgba(14,200,218,0.3)'}
                  ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 1, color: 'rgba(14,200,218,0.8)'},
                      {offset: 0, color: 'rgba(14,200,218,0.3)'}
                    ]
                )
              }
            },
            type: 'bar',
            data: (function () {
              let now = chartsData
              let res = [];
              let len = chartsData.length>2? 2 : chartsData.length;
              while (len--) {
                res.unshift(now[len].effectiveness)
              }
              return res;
            })()
          }
        ]
      };
      if (chartsData.length > 2 ) {
        let app = {}
        app.count = 2;
        this.time2 = setInterval(function () {
          let axisData1 = chartsData[app.count].machine;
          let axisData2 = chartsData[app.count].machine;
          let axisData3 = chartsData[app.count].effectiveness;

          let data0 = option.series[0].data;
          data0.shift();
          data0.push(axisData3);
          option.xAxis[0].data.shift();
          option.xAxis[0].data.push(axisData1);
          option.xAxis[1].data.shift();
          option.xAxis[1].data.push(axisData2);
          app.count ++
          if(app.count === chartsData.length) {
            app.count = 0
          }
          myChart.dispatchAction({
            type: 'showTip',
            //屏幕上的x坐标
            x: 245,
            //屏幕上的y坐标
            y: 122,
          });
          myChart.setOption(option, false);


        }, 600000);
        myChart.on('mouseover',(even)=>{
          // console.log(even,'even')
          clearInterval(this.time2)
        })
        myChart.on('mouseout',(even)=>{
          clearInterval(this.time2)
          this.time2 = setInterval(function () {
            let axisData1 = chartsData[app.count].machine;
            let axisData2 = chartsData[app.count].machine;
            let axisData3 = chartsData[app.count].effectiveness;

            let data0 = option.series[0].data;
            data0.shift();
            data0.push(axisData3);
            option.xAxis[0].data.shift();
            option.xAxis[0].data.push(axisData1);
            option.xAxis[1].data.shift();
            option.xAxis[1].data.push(axisData2);
            app.count ++
            if(app.count === chartsData.length) {
              app.count = 0
            }
            myChart.dispatchAction({
              type: 'showTip',
              //屏幕上的x坐标
              x: 245,
              //屏幕上的y坐标
              y: 122,
            });
            myChart.setOption(option, false);


          }, 600000);
        })
      }else {
        myChart.setOption(option, false);
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,  // 显示第几个series
          dataIndex: 0// 显示第几个数据
        });
        myChart.on('mouseout',(even)=>{
          if(this.time2) clearInterval(this.time2)
          this.time2 = setInterval(function () {
            myChart.setOption(option, false);
            myChart.dispatchAction({
              type: 'showTip',
              seriesIndex: 0,  // 显示第几个series
              dataIndex: 0// 显示第几个数据
            });
          },600000)
        })
      }

    },
    // 生产产量配置
    setChartsForCL(echartData) {

      const myChart = this.$echarts.init(document.getElementById('output'));
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              let now = echartData.times
              let res = [];
              let len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })()
          },
          {
            type: 'category',
            boundaryGap: true,
            show: false,
            data: (function () {
              var now = echartData.times
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '产量明细',
            min: 0,
            boundaryGap: [0.2, 0.2]
          },
          {
            type: 'value',
            scale: true,
            name: '累计产量',
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '产量明细',
            type: 'bar',
            barWidth: 20,
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: (function () {
              var now = echartData.decimal
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })(),
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: 'rgba(54,176,254,0.8)'},
                    {offset: 0, color: 'rgba(54,176,254,0.3)'}
                  ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 1, color: 'rgba(54,176,254,0.8)'},
                      {offset: 0, color: 'rgba(54,176,254,0.3)'}
                    ]
                )
              }
            },
          },
          {
            name: '累计产量',
            type: 'line',
            data: (function () {
              var now = echartData.allDecimal
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })(),
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: 'rgba(54,176,254,0.8)'},
                    {offset: 0, color: 'rgba(54,176,254,0.3)'}
                  ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 1, color: 'rgba(54,176,254,0.8)'},
                      {offset: 0, color: 'rgba(54,176,254,0.3)'}
                    ]
                )
              }
            },
          }
        ]
      };
      let app = {}
      app.count = 11;
      this.timer = setInterval(function () {

        let axisData1 = echartData.times[app.count];
        let axisData2 = echartData.decimal[app.count];
        let axisData3 = echartData.allDecimal[app.count];

        let data0 = option.series[0].data;
        let data1 = option.series[1].data;
        data0.shift();
        data0.push(axisData2);
        data1.shift();
        data1.push(axisData3);

        option.xAxis[0].data.shift();
        option.xAxis[0].data.push(axisData1);
        option.xAxis[1].data.shift();
        option.xAxis[1].data.push(axisData1);
        app.count ++
        if(app.count === echartData.times.length) {
          app.count = 0
        }
        myChart.setOption(option,true);
      }, 600000);


      // 鼠标移入后停止移动
      myChart.on('mouseover',(even)=>{
        clearInterval(this.timer)
      })
      myChart.on('mouseout',(even)=>{
        clearInterval(this.timer)
        this.timer = setInterval(function () {
          let axisData1 = echartData.times[app.count];
          let axisData2 = echartData.decimal[app.count];
          let axisData3 = echartData.allDecimal[app.count];

          let data0 = option.series[0].data;
          let data1 = option.series[1].data;
          data0.shift();
          data0.push(axisData2);
          data1.shift();
          data1.push(axisData3);

          option.xAxis[0].data.shift();
          option.xAxis[0].data.push(axisData1);
          option.xAxis[1].data.shift();
          option.xAxis[1].data.push(axisData1);
          app.count ++
          if(app.count === echartData.times.length) {
            app.count = 0
          }
          myChart.setOption(option,true);
        }, 600000);
      })
    },
    // 当日人员平均产量排名
    setChartsForRY(xData,yData) {
      const myChart = this.$echarts.init(document.getElementById('average'));
      var option = {
        xAxis: {
          type: 'value',
          boundaryGap: false,
        },
        grid: {
          x: '20px', //相当于距离左边效果:padding-left
          y: '20px',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'category',

          data: yData
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        series: [{
          data: xData,
          type: 'bar',
          barWidth: 20,
          itemStyle: {
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [0, 15, 15, 0],
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: '#E2B3F7'},
                    {offset: 0, color: '#C17CED'}
                  ]
              ),
            }
          },
          emphasis: {
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: '#E2B3F7'},
                    {offset: 0, color: '#C17CED'}
                  ]
              )
            }
          },
        }]
      };
      myChart.setOption(option);
    },
    // 当日班组平均产量排名
    setChartsForBZ(xData,yData) {
      const myChart = this.$echarts.init(document.getElementById('groupCharts'));
      var option = {
        xAxis: {
          type: 'value',
          boundaryGap: false,
        },
        grid: {
          x: '20px', //相当于距离左边效果:padding-left
          y: '20px',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'category',
          data: yData
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        series: [{
          data: xData,
          type: 'bar',
          barWidth:20,
          itemStyle: {
            normal: {
              //柱形图圆角，初始化效果
              barBorderRadius: [0, 15, 15, 0],
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: '#88DAFF'},
                    {offset: 0, color: '#45B2FF'}
                  ]
              ),
            }
          },
          emphasis: {
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: '#88DAFF'},
                    {offset: 0, color: '#45B2FF'}
                  ]
              )
            }
          },
        }]
      };
      myChart.setOption(option);
    },
    // 获取生产能耗
    async getProductionEnergyConsumption(type){
      let dayTime = dateFormat(new Date())
      let api
      if(type == 1){
        api = this.proApis.SELECTENERGYCONSUMPTIONTODAY+"?dayTime="+dayTime // 当天
      }else if( type == 2) {
        api = this.proApis.SELECTBYMONTH+"?monthTime="+dayTime //当月
      }else if( type == 3) {
        api = this.proApis.SELECTBYYEAR+"?dateTime="+dayTime //当年
      }
      clearInterval(this.timer3)
      const res = await this.request.get(api)
    //  console.log(res,'resss')
      this.setChartsForNH(res.data)
    },
    // 生产能耗变化
    nhChange(){
      this.getProductionEnergyConsumption(this.btnType2)
    },
    // 生产能耗
    setChartsForNH(echartData) {

      const myChart = this.$echarts.init(document.getElementById('energy'));
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              let now = echartData.times
              let res = [];
              let len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })()
          },
          {
            type: 'category',
            boundaryGap: true,
            show: false,
            data: (function () {
              var now = echartData.times
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '累计能耗',
            min: 0,
            boundaryGap: [0.2, 0.2]
          },
          {
            type: 'value',
            scale: true,
            name: '能耗',
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '能耗明细',
            type: 'bar',
            barWidth: 20,
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: (function () {
              var now = echartData.decimal
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })(),
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: 'rgba(72,60,255,0.8)'},
                    {offset: 0, color: 'rgba(72,60,255,0.3)'},
                  ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 1, color: 'rgba(72,60,255,0.8)'},
                      {offset: 0, color: 'rgba(72,60,255,0.3)'},
                    ]
                )
              }
            },
          },
          {
            name: '累计能耗',
            type: 'line',
            data: (function () {
              var now = echartData.allDecimal
              var res = [];
              var len = 10;
              while (len--) {
                res.unshift(now[len])
              }
              return res;
            })(),
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 1, color: 'rgba(72,60,255,0.8)'},
                    {offset: 0, color: 'rgba(72,60,255,0.3)'},
                  ]
              )
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 1, color: 'rgba(72,60,255,0.8)'},
                      {offset: 0, color: 'rgba(72,60,255,0.3)'},
                    ]
                )
              }
            },
          }
        ]
      };
      let app = {}
      app.count = 11;
      this.timer3 = setInterval(function () {
        let axisData1 = echartData.times[app.count];
        let axisData2 = echartData.decimal[app.count];
        let axisData3 = echartData.allDecimal[app.count];

        let data0 = option.series[0].data;
        let data1 = option.series[1].data;
        data0.shift();
        data0.push(axisData2);
        data1.shift();
        data1.push(axisData3);

        option.xAxis[0].data.shift();
        option.xAxis[0].data.push(axisData1);
        option.xAxis[1].data.shift();
        option.xAxis[1].data.push(axisData1);
        app.count ++
        if(app.count === echartData.times.length) {
          app.count = 0
        }
        myChart.setOption(option,true);
      }, 2100);
      myChart.on('mouseover',(even)=>{
        clearInterval(this.timer3)
      })
      myChart.on('mouseout',(even)=>{
        clearInterval(this.timer3)
        this.timer3 = setInterval(function () {
          let axisData1 = echartData.times[app.count];
          let axisData2 = echartData.decimal[app.count];
          let axisData3 = echartData.allDecimal[app.count];

          let data0 = option.series[0].data;
          let data1 = option.series[1].data;
          data0.shift();
          data0.push(axisData2);
          data1.shift();
          data1.push(axisData3);

          option.xAxis[0].data.shift();
          option.xAxis[0].data.push(axisData1);
          option.xAxis[1].data.shift();
          option.xAxis[1].data.push(axisData1);
          app.count ++
          if(app.count === echartData.times.length) {
            app.count = 0
          }
          myChart.setOption(option,true);
        }, 600000);
      })
    },
    // 全屏事件
    handleFullScreen() {
      // let element = document.documentElement;//设置后就是我们平时的整个页面全屏效果
      let element = document.getElementById('home_container');//设置后就是   id==con_lf_top_div 的容器全屏
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    }
  }
};
</script>

<style lang="scss" scoped>
#home_container {
  padding: 0 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .item_row {
    margin: 0.25rem 0;

    .left {
      width: 100%;
      border-radius: 0.625rem;
      height: 4.375rem;
      display: flex;
      justify-content: space-around;
      background-color: #fff;
      padding: 0.625rem;
      box-sizing: border-box;
      a {
        color: #FFFFFF;
      }
      .last {
       .el-button {
         height: 50px;
         a {
           color: #000;
         }
       }
      }
    }
    .topDiv {
        width: 100%;
        border-radius: 0.625rem;
        height: 6.1875rem;
        background-color: #fff;
        padding: 0.625rem 1rem;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        text-align:center;
    .g {
        color: #00B7FF;
        text-align:center;
    }
    .r {
        color: #FF5A52;
        text-align: center;
    }
    .b {
        color: #0D57C2;
        text-align: center;
    }
      
      .group_right {
        font-size: 20px;
        /*margin-top: 20px;*/
      }
    }
    .group {
      font-size: 1.25rem;
      position: relative;
      top: 0.625rem;
    }

    .kanban {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;

      .num {
        background-color: #fff;
        width: 14.25rem;
        border-radius: 0.625rem;
        height: 4.375rem;

        .content {
          height: 4.375rem;
          position: relative;

          img {
            position: absolute;
            width: 2.8125rem;
            height: 2.8125rem;
            top: 50%;
            left: 1.875rem;
            transform: translateY(-50%);
          }

          .title {
            width: 100%;
            text-align: center;
            font-size: 0.9375rem;
            margin-top: 0.5rem;
          }

          .numb {
            width: 100%;
            text-align: center;
            font-size: 1.625rem;
            font-weight: 700;
          }
        }
      }
    }
    .twoOutDiv {
        width: 100%;
        display: flex;
        border-radius: 0.625rem;
        height: 4.8125rem;
        background-color: #fff;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0.5rem 15px;
        .twoDiv{
            width:100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-wrap: wrap;
            overflow:hidden;
        /*.outDiv{
                display:flex;
                align-items:center;
            }*/
        .text{
                display: flex;
                align-items: center;
                height: 50%;
                width: 30%;
                position:relative;
            }

        }
    }
    
    .public {
        width: 100%;
        display: flex;
        border-radius: 0.625rem 0.625rem 0 0;
        height: 2.8125rem;
        padding: 0.25rem 0 0 0.8125rem;
        background-color: #fff;
        border-bottom: 1px solid #EBEBEB;
        box-sizing: border-box;
        img {
        height: 1.15rem;
        margin-right: 0.625rem;
        position: relative;
        top: 0.6rem;
      }

      .tit {
        font-size: 1.25rem;
        position: relative;
        top: 0.25rem;
      }

      span {
        font-weight: 700;
      }
    }

    .select_container {
      background-color: #fff;
      //padding-top: 10px;
      //display: inline-block;

    }

    .charts {
      background-color: #fff;
      width: 100%;
      border-radius: 0 0 10px 10px;
    }

    .container {
      height: 18.75rem;
      position: relative;

      .btn {
        padding-top: 1.25rem;
        text-align: center;
        z-index: 99999;
        margin-left: 2.5rem;
      }
    }

    .lineP {
      height: 150px;
      padding: 1.25rem;
      padding-left: 2.25rem;
      box-sizing: border-box;
      display: flex;

      .el-progress {
        width: 100%;
      }

      /deep/ .el-progress-bar {
        width: 100%;
        margin: 0 0 0.5rem 0;
      }

      /deep/ .el-progress__text {
        width: 84%;
        text-align: center;
        color: #FFAA00;
      }

      //.item_line {
      //  width: 50%;
      //  //margin: 2.5rem 0;
      //  .green {
      //    /deep/ .el-progress__text {
      //      color: #00D643;
      //    }
      //  }
      //
      //  .title {
      //    font-size: 1.125rem;
      //    position: relative;
      //    left: -1.5rem;
      //    color: #333333;
      //    text-align: center;
      //    font-weight: 700;
      //    margin-bottom: 1rem;
      //    margin-top: 1rem;
      //  }
      //}
    }

    .progress {
      height: 9.375rem;
      display: flex;
      justify-content: space-around;
      padding: 1.25rem;
      box-sizing: border-box;

      /deep/ .el-progress-circle {
        height: 5rem !important;
        width: 5rem !important;
      }

      .item_ca {
        position: relative;

        .title {
          text-align: center;
          color: #747E8E;
          font-size: 1rem;
        }

        .nub {
          font-size: 1.25rem;
          color: #747E8E;
          text-align: center;
          font-weight: 700;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        /deep/ .el-progress--dashboard {
          transform: rotateX(180deg);
        }

        /deep/ .el-progress__text {
          transform: translateY(-50%) rotateX(180deg);
        }

      }
    }
  }
}
    .bgtl {
        border-bottom: 1px solid #00bdf2;
        padding: 0 !important;
        height: 50px;
        line-height: 50px;
        color: #fff;
    }
    .bgtr {
        border-bottom: 1px solid #00bdf2;
        padding: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        line-height: 50px;
        color: #fff;
    }
    .bgtc {
        position: relative;
        height: 88px;
    }
        .bgtc p {
            border-bottom: 1px solid #00bdf2;
            height:86px;
            width:calc(100% - 180px);
            margin-left:90px;
            line-height:80px;
            color:#fff;
            font-weight:700;
        }
        .bgtc:after {
            content: "";
            width: 100px;
            height: 100%;
            background: url('/img/datl.png') 100% no-repeat;
            background-position: left 50px;
            background-size: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    .bgtc:before {
        content: "";
        width: 100px;
        height: 100%;
        background: url('/img/datr.png') 100% no-repeat;
        background-position: left 50px;
        background-size: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    #topTit{
        display:none;
    }
    .bdDiv{
        position:relative;
    }
    .afDiv:after {
        content:"";
        position:absolute;
        right:-1px;
        top:-1px;
        width:50px;
        height:50px;
        background:url('/img/dart.png') no-repeat;
        background-position:top right;
        background-size:100%;
    }
    .afDiv:before {
        content: "";
        position: absolute;
        right: -1px;
        top: -1px;
        width: 49px;
        height: 49px;
        border-top: 1px solid #020912;
        border-right: 1px solid #020912;
    }
</style>
