var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-tabs",
            {
              attrs: { option: _vm.option, data: _vm.data, page: _vm.page },
              on: {
                change: _vm.handleChange,
                submit: _vm.handleSubmit,
                "selection-change": _vm.selectionChange
              },
              model: {
                value: _vm.form,
                callback: function($$v) {
                  _vm.form = $$v
                },
                expression: "form"
              }
            },
            [
              _c(
                "template",
                { slot: "before" },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.handleDel } },
                    [_vm._v("全部已读")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.handleDel } },
                    [_vm._v("标记已读")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { size: "small" }, on: { click: _vm.handleDel } },
                    [_vm._v("删除")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }