var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "home_container" } },
    [
      _c(
        "el-row",
        {
          staticClass: "item_row ",
          staticStyle: { width: "100%" },
          attrs: { gutter: 20, id: "topTit" }
        },
        [
          _c("el-col", { staticClass: "bgtl", attrs: { span: 6 } }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.nowDate) +
                  "   " +
                  _vm._s(_vm.bcName) +
                  "   " +
                  _vm._s(_vm.teamName)
              )
            ])
          ]),
          _c("el-col", { staticClass: "bgtc", attrs: { span: 12 } }, [
            _c(
              "p",
              { staticStyle: { "text-align": "center", "font-size": "2rem" } },
              [_vm._v("数字化生产管理系统")]
            )
          ]),
          _c("el-col", { staticClass: "bgtr", attrs: { span: 6 } }, [
            _c("div", { staticStyle: { width: "100px" } }, [
              _c("p", [
                _vm._v(_vm._s(_vm.cjName) + "   " + _vm._s(_vm.precessName))
              ])
            ]),
            _c("p", { staticStyle: { "text-align": "right" } }, [
              _vm._v("MES 系统V1.2")
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "item_row ",
          staticStyle: { width: "100%" },
          attrs: { gutter: 20, id: "topSel" }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "align-items": "center",
                display: "flex",
                "justify-content": "space-between"
              }
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "text-align": "left",
                    display: "flex",
                    "align-items": "center"
                  },
                  attrs: { span: 18 }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "date",
                      staticStyle: { "margin-right": "15px" }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "20px",
                          "margin-right": "10px",
                          "vertical-align": "-4px",
                          "margin-top": "2px"
                        },
                        attrs: { src: "/img/button/日历.png", alt: "" }
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.nowDate))])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "group_right",
                      staticStyle: { "margin-right": "15px" }
                    },
                    [
                      _vm._v(
                        "\n                    当前班次: " +
                          _vm._s(_vm.bcName) +
                          "\n                "
                      )
                    ]
                  ),
                  _c("div", { staticClass: "group_right" }, [
                    _vm._v(
                      "\n                    当前班组: " +
                        _vm._s(_vm.teamName) +
                        "\n                "
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "text-align": "right",
                    display: "flex",
                    "align-items": "center"
                  },
                  attrs: { span: 6 }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { margin: "10px" },
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.cjsel },
                      model: {
                        value: _vm.cjvalue,
                        callback: function($$v) {
                          _vm.cjvalue = $$v
                        },
                        expression: "cjvalue"
                      }
                    },
                    _vm._l(_vm.cjlist, function(item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.precesssel },
                      model: {
                        value: _vm.precessvalue,
                        callback: function($$v) {
                          _vm.precessvalue = $$v
                        },
                        expression: "precessvalue"
                      }
                    },
                    _vm._l(_vm.precesslist, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-row",
        {
          staticClass: "item_row",
          staticStyle: { width: "100%" },
          attrs: { gutter: 20 }
        },
        [
          _c("el-col", { attrs: { span: 5 } }, [
            _c("div", { staticClass: "topDiv bdDiv" }, [
              _c("div", { staticClass: "g" }, [
                _c("p", { staticStyle: { "margin-bottom": "9px" } }, [
                  _vm._v("开台数量")
                ]),
                _c("p", [_vm._v(_vm._s(_vm.mbDataList.openCount || "0"))])
              ]),
              _c("div", { staticClass: "r" }, [
                _c("p", { staticStyle: { "margin-bottom": "9px" } }, [
                  _vm._v("品种数量")
                ]),
                _c("p", [_vm._v(_vm._s(_vm.mbDataList.productCount || "0"))])
              ]),
              _c("div", { staticClass: "b" }, [
                _c("p", { staticStyle: { "margin-bottom": "9px" } }, [
                  _vm._v("落纱次数")
                ]),
                _c("p", [_vm._v(_vm._s(_vm.mbDataList.doffingNumber || "0"))])
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "topDiv bdDiv" }, [
              _c(
                "div",
                { staticStyle: { width: "40px", "text-align": "center" } },
                [
                  _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                    _vm._v(" ")
                  ]),
                  _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                    _vm._v("当班")
                  ]),
                  _c("p", [_vm._v("前班")])
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "align-content": "center",
                    width: "calc(100% - 40px)",
                    "text-align": "center"
                  }
                },
                [
                  _c("div", [
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v("总产量(kg)")
                    ]),
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(_vm._s(_vm.mbDataList.departmentAddYield || "0"))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.oddmbDataList.totalYield || "0"))
                    ])
                  ]),
                  _c("div", [
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v("盈亏产量(kg)")
                    ]),
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(_vm._s(_vm.mbDataList.lossAddEnergy || "0"))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.oddmbDataList.lossYield || "0"))
                    ])
                  ]),
                  _c("div", [
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v("计划产量(kg)")
                    ]),
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(_vm._s(_vm.mbDataList.totalDayYield || "0"))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.oddmbDataList.planYield || "0"))
                    ])
                  ]),
                  _c("div", [
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v("总能耗(kwh)")
                    ]),
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(_vm._s(_vm.mbDataList.departmentAddEnergy || "0"))
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.oddmbDataList.totalEnergyConsumption || "0")
                      )
                    ])
                  ])
                ]
              )
            ])
          ]),
          _c("el-col", { attrs: { span: 5 } }, [
            _c("div", { staticClass: "topDiv bdDiv" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "g",
                        staticStyle: { "margin-bottom": "5px" }
                      },
                      [_vm._v("在纺台数")]
                    ),
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _c("span", { staticClass: "g" }, [
                        _vm._v(_vm._s(_vm.mbDataList.openCount || "0"))
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "b",
                        staticStyle: { "margin-bottom": "5px" }
                      },
                      [_vm._v("落纱台数")]
                    ),
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _c("span", { staticClass: "b" }, [
                        _vm._v(_vm._s(_vm.mbDataList.doffingCount || "0"))
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "r",
                        staticStyle: { "margin-bottom": "5px" }
                      },
                      [_vm._v("停机台数")]
                    ),
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _c("span", { staticClass: "r" }, [
                        _vm._v(_vm._s(_vm.mbDataList.offCount || "0"))
                      ])
                    ])
                  ])
                ]
              )
            ])
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { staticClass: "topDiv bdDiv" }, [
              _c("div", { staticClass: "r" }, [
                _c("p", { staticStyle: { "margin-bottom": "9px" } }, [
                  _vm._v("平均车速(rpm/min)")
                ]),
                _c("p", [_vm._v(_vm._s(_vm.mbDataList.avgSpeed || "0"))])
              ]),
              _c("div", { staticClass: "b" }, [
                _c("p", { staticStyle: { "margin-bottom": "9px" } }, [
                  _vm._v("平均吨纱能耗(kwh)")
                ]),
                _c("p", [_vm._v(_vm._s(_vm.mbDataList.avgAddEnergy || "0"))])
              ]),
              _c("div", { staticClass: "g" }, [
                _c("p", { staticStyle: { "margin-bottom": "9px" } }, [
                  _vm._v("综合效率")
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.mbDataList.totalEffectiveness || "0") + "%")
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "item_row",
          staticStyle: { width: "100%" },
          attrs: { gutter: 20 }
        },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "bdDiv" }, [
              _c("div", { staticClass: "public" }, [
                _c("img", { attrs: { src: "/img/button/角标.png", alt: "" } }),
                _c("span", { staticClass: "tit" }, [
                  _vm._v("品种支数吨纱能耗 kwh/t")
                ])
              ]),
              _c("div", { staticClass: "charts container" }, [
                _c("div", {
                  staticStyle: { width: "100%", height: "18.75rem" },
                  attrs: { id: "banzhi" }
                })
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "bdDiv" }, [
              _c("div", { staticClass: "public" }, [
                _c("img", { attrs: { src: "/img/button/角标.png", alt: "" } }),
                _c("span", { staticClass: "tit" }, [
                  _vm._v("班次能耗及吨纱耗电")
                ])
              ]),
              _c("div", { staticClass: "charts container" }, [
                _c("div", {
                  staticStyle: { width: "100%", height: "18rem" },
                  attrs: { id: "pinzhong" }
                })
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "bdDiv" }, [
              _c("div", { staticClass: "public" }, [
                _c("img", { attrs: { src: "/img/button/角标.png", alt: "" } }),
                _c("span", { staticClass: "tit" }, [_vm._v("设备效率排名 %")])
              ]),
              _c("div", { staticClass: "charts container" }, [
                _c("div", {
                  staticStyle: { width: "100%", height: "18rem" },
                  attrs: { id: "shebei" }
                })
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "item_row",
          staticStyle: { width: "100%" },
          attrs: { gutter: 20 }
        },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "bdDiv" }, [
              _c("div", { staticClass: "public" }, [
                _c("img", { attrs: { src: "/img/button/角标.png", alt: "" } }),
                _c("span", { staticClass: "tit" }, [_vm._v("机台实时状态")])
              ]),
              _c("div", { staticClass: "charts container" }, [
                _c("div", {
                  staticStyle: { width: "100%", height: "18rem" },
                  attrs: { id: "jitai" }
                })
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }