import request from '@/router/axios';
import { businessUrl } from '@/config/env';
//��ȡ�������
export const getData = (id, code) => request({
    url: businessUrl + "kanban/selectStatus",
	method: "get",
	params: {
		workshopId: id,
		processCode:code
	}
})
//����
export const calssList = (type) => {
    return request({
        url: businessUrl + "department/selectWorkShopName",
        method: "get",
        params: {
            type: type
        }
    })
}
export const precessList = () => {
	return request({
		url: businessUrl + "process/selectAll",
		method: "get",
	})
}
//��ȡƷ��֧��
export const getProd = (id, code) => request({
    url: businessUrl + "kanban/selectProductShif",
	method: "get",
	params: {
		workshopId: id,
		processCode: code
	}
})
//��ȡ����ܺ�
export const getShop = (id, code) => request({
    url: businessUrl + "kanban/selectWorkShopShif",
	method: "get",
	params: {
		workshopId: id,
		processCode: code
	}
})
//��ȡ�豸����
export const getMachData = (id, code) => request({
    url: businessUrl + "kanban/selectMachineEfficiency",
	method: "get",
	params: {
		workshopId: id,
		processCode: code
	}
})
//��ȡ��̨
export const getOpenM = (id, code) => request({
    url: businessUrl + "kanban/selectAllOpenMachine",
	method: "get",
	params: {
		workshopId: id,
		processCode: code
	}
})


export const Counter = (options) => {
	console.log(options,'aaaa')
	this.init(options);
}

Counter.__DEFAULTS__ = {
	// չʾЧ����Ԫ��
	el: null,
	// ��ʼ��
	fromNumber: 0,
	// ������
	toNumber: 0,
	// ����֧��С��
	enableFloat: false,
	// ����ʱ��
	duration: 300
};

Counter.prototype.init = function (options) {
	this.precision = getPrecision(options.toNumber);
	this.setOptions(options);
	if (typeof this.el === 'string') {
		this.el = document.querySelector(this.el);
	}
	console.log(this.toNumber);

	if (!(this.el instanceof Element)) {
		throw TypeError('el must be a element.');
	}
}

Counter.prototype.setOptions = function (options) {
	var defaults = Counter.__DEFAULTS__, opts = options && typeof options === 'object' ? options : {}, k;
	for (k in defaults) {
		this[k] = defaults[k];
	}
	for (k in this) {
		if (k in opts && typeof opts[k] !== 'undefined'
			&& opts[k] !== this[k]
		) {
			if (typeof this[k] === 'number') {
				opts[k] = parseFloat(opts[k]) || this[k];
			}

			else if (typeof this[k] === 'boolean') {
				opts[k] = !!opts[k];
			}

			else if (typeof this[k] === 'object') {
				opts[k] = typeof opts[k] === 'object' ? opts[k] : this[k];
			}

			else {
				opts[k] = this[k].construtor(opts[k]);
			}

			if (this[k] == null || typeof (this[k]) === typeof (opts[k])) {
				this[k] = opts[k];
			}
		}
	}
}

Counter.prototype.start = function () {
	const self = this,
		from = parseFloat(self.fromNumber),
		to = parseFloat(self.toNumber),
		enableFloat = self.enableFloat,
		precision = self.precision,
		duration = self.duration,
		el = self.el,
		startTime = Date.now();

	const update = function () {
		var nowTime = Date.now(),
			ratio = Math.min(1, (nowTime - startTime) / duration),
			result = ratio * (to - from) + from;
		console.log(from, self.toNumber);
		if (enableFloat) {
			result = result.toFixed(precision);
		} else {
			result = Math.floor(result);
		}

		if (ratio == 1 || el.innerHTML == to) {
			clearInterval(self.__timerId__);
		}

		el.innerHTML = result;
	};
	if (self.__timerId__) {
		clearInterval(self.__timerId__);
	}
	self.__timerId__ = setInterval(update, 1000 / 60);
}

// ��ȡС���㾫��
function getPrecision(num) {
	var n = num + '', i = n.indexOf('.') + 1;
	n = n.slice(i, n.length);
	return i ? n.length : i;
}
// �Զ��巽��
Math.getPrecision = getPrecision;
global.Counter = Counter;