<template>
    <div id="home_container">
        <div id="pvhinfo_container">
            <el-container>
                <el-header class="headerDiv" style="padding:0;">
                    <el-row style="width: 100%;">
                        <el-col :span="24" style="text-align:right;margin-bottom: 0.5rem!important;">
                            <el-date-picker v-model="yearVal"
                                            type="year" size="mini"
                                            :placeholder="$t('Year')"
                                            @change="yearselectChanged">
                            </el-date-picker>
                        </el-col>
                    </el-row>
                    <el-row style="width: 100%;">
                        <el-col :span="8" style="padding-right: 20px;">
                            <div class="chartsDiv">
                                <div class="chartsHeaderDiv" style="display:flex;justify-content:space-between;align-items:center;">
                                    <div style="text-align:left;width:calc(100% - 65px);">
                                        <img class="titImg" src="/img/button/angleMark.png" alt="">
                                        <span class="tit" :title="$t('ORDER QUANTITY')" style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: calc(100% - 14px);display: inline-block;vertical-align: middle;">{{$t('ORDER QUANTITY')}}</span>
                                    </div>
                                    <div>
                                        <el-select v-model="charts01_dataVal" size="mini" :placeholder="$t('Month')" style="width:85px" @change="data1selectChanged">
                                            <el-option v-for="item in dataMonths"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="chartsBodyDiv">
                                    <template>
                                        <div id="charts_01" style="width: 100%;height:198px;padding:10px 0 5px 0;" v-loading="loading01"></div>
                                    </template>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8" style="padding-right: 20px;">
                            <div class="chartsDiv">
                                <div class="chartsHeaderDiv" style="display:flex;justify-content:space-between;align-items:center;">
                                    <div style="text-align:left;width:calc(100% - 165px);">
                                        <img src="/img/button/angleMark.png" class="titImg" alt="">
                                        <span class="tit" :title="$t('TOTAL BRAND ANALYSIS')" style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: calc(100% - 14px);display: inline-block;vertical-align: middle;">{{$t('TOTAL BRAND ANALYSIS')}}</span>
                                    </div>
                                    <div>
                                        <el-select v-model="charts02_brandVal" size="mini" filterable :placeholder="$t('Brand')" style="width:65px;margin-right:5px;" @change="brandselectChanged">
                                            <el-option v-for="item in brandData"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-select v-model="charts02_dataVal" size="mini" style="width:85px" :placeholder="$t('Month')" @change="data2selectChanged">
                                            <el-option v-for="item in dataMonths"
                                                       :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="chartsBodyDiv">
                                    <div id="charts_02" style="width: 100%;height:198px;padding:10px 0 5px 0;" v-loading="loading02"></div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="chartsDiv">
                                <div class="chartsHeaderDiv" style="display:flex;justify-content:space-between;align-items:center;">
                                    <div style="text-align:left;width:100%;">
                                        <img src="/img/button/angleMark.png" class="titImg" alt="">
                                        <span class="tit" :title="$t('WRITE OFF QUANTITY OF RAW MATERIAL ORDER')" style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: calc(100% - 14px);display: inline-block;vertical-align: middle;">{{$t('WRITE OFF QUANTITY OF RAW MATERIAL ORDER')}}</span>
                                    </div>
                                </div>
                                <div class="chartsBodyDiv">
                                    <div id="charts_03" style="width: 100%;height:198px;padding:10px 0 5px 0;" v-loading="loading03"></div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                </el-header>
                <el-container>
                    <el-row style="width:100%;">
                        <el-col :span="8" style="padding-right:20px;">
                            <el-row style="width: 100%;">
                                <el-col :span="24">
                                    <div class="chartsDiv">
                                        <div class="chartsHeaderDiv" style="display:flex;justify-content:space-between;align-items:center;">
                                            <div style="text-align:left;width:100%;">
                                                <img src="/img/button/angleMark.png" class="titImg" alt="">
                                                <span class="tit" :title="$t('SUPPLIER CERTIFICATION QUANTITY')" style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: calc(100% - 14px);display: inline-block;vertical-align: middle;">{{$t('SUPPLIER CERTIFICATION QUANTITY')}}</span>
                                            </div>
                                        </div>
                                        <div class="chartsBodyDiv">
                                            <div id="charts_04" style="width: 100%;height:198px;padding:10px 0 5px 0;" v-loading="loading04"></div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="width: 100%;">
                                <el-col :span="24">
                                    <div class="chartsDiv">
                                        <div class="chartsHeaderDiv" style="display:flex;justify-content:space-between;align-items:center;">
                                            <div style="text-align:left;width:calc(100% - 150px);">
                                                <img src="/img/button/angleMark.png" class="titImg" alt="">
                                                <span class="tit" :title="$t('TOP 10')" style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: calc(100% - 14px);display: inline-block;vertical-align: middle;">{{$t('TOP 10')}}</span>
                                            </div>
                                            <div>
                                                <el-select v-model="charts05_Val" size="mini" filterable :placeholder="$t('Classification')" style="width:150px;" @change="charts05selectChanged">
                                                    <el-option v-for="item in charts05Data"
                                                               :key="item.value"
                                                               :label="item.label"
                                                               :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                        <div class="chartsBodyDiv">
                                            <div id="charts_05" style="width: 100%;height:198px;padding:10px 0 5px 0;" v-loading="loading05"></div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="16">
                            <el-main>
                                <el-row style="width: 100%;">
                                    <el-col :span="24">

                                        <div class="chartsMapDiv">
                                            <div class="chartsHeaderMapDiv" style="display:flex;justify-content:space-between;align-items:center;">
                                                <div style="text-align:left;width:calc(100% - 250px);">
                                                    <img src="/img/button/angleMark.png" class="titImg" alt="">
                                                    <span class="tit" :title="$t('GLOBAL QUANTITATIVE INFORMATION STATISTICS')" style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: calc(100% - 14px);display: inline-block;vertical-align: middle;">{{$t('GLOBAL QUANTITATIVE INFORMATION STATISTICS')}}</span>
                                                </div>
                                                <div>
                                                    <div id="mapBig" style="display:inline-block;margin-right:10px;cursor:pointer;" @click="bigScr">
                                                        <img style="vertical-align: middle;" src="/img/mapScr.png" />
                                                    </div>
                                                    <el-select v-model="vendorVal" size="mini" :placeholder="$t('Supplier')" clearable style="width:120px" @change="mapselectChanged">
                                                        <el-option v-for="item in vendorData"
                                                                   :key="item.value"
                                                                   :label="item.label"
                                                                   :value="item.value">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                            <div class="chartsBodyMapDiv" id="mapDiv">
                                                <div id="mapTopSear" style="position:fixed;top:0;left:0;width:100%;background:rgba(0,0,0,.8);padding:10px 40px;z-index:999;display:none;">
                                                    <el-autocomplete class="inline-input mapIpt" style=""
                                                                     v-model="POd"
                                                                     :fetch-suggestions="poSearch"
                                                                     :placeholder="$t('PO')"
                                                                     :popper-append-to-body="false"
                                                                     clearable></el-autocomplete>
                                                    <el-autocomplete class="inline-input mapIpt" style=""
                                                                     v-model="proCode"
                                                                     :fetch-suggestions="pcSearch"
                                                                     :placeholder="$t('ProductCode')"
                                                                     :popper-append-to-body="false"
                                                                     clearable></el-autocomplete>
                                                    <el-autocomplete class="inline-input mapIpt" style=""
                                                                     v-model="proName"
                                                                     :fetch-suggestions="pnSearch"
                                                                     :placeholder="$t('Product Name')"
                                                                     :popper-append-to-body="false"
                                                                     clearable></el-autocomplete>
                                                    <div style="display:inline-block;cursor:pointer;" @click="mapScSear">
                                                        <img style="vertical-align:middle" src="/img/sr.png" />
                                                    </div>
                                                </div>
                                                <div id="mapTwo" style="position:fixed;top:80px;right:20px;;background:#fff;padding:40px 20px;z-index:999;display:none;">
                                                    <div style="display:flex;align-items:center;justify-content:space-between;">
                                                        <div style="text-align:center;padding:0 20px;">
                                                            <img style="width:2rem;margin-bottom:10px;" src="/img/yf1.png" />
                                                            <p style="margin-bottom:10px;font-size:1.25rem;font-weight:700;">{{totSysOrd}}</p>
                                                            <p style="font-size:0.7rem;">{{$t('TOTAL SYSTEM ORDERS')}}</p>
                                                        </div>
                                                        <div style="text-align:center;padding:0 20px;">
                                                            <img style="width:2rem;margin-bottom:10px;" src="/img/yf2.png" />
                                                            <p style="margin-bottom:10px;font-size:1.25rem;font-weight:700;">{{numOrdCom}}</p>
                                                            <p style="font-size:0.7rem;">{{$t('COMPLETED')}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="mapScrTot" style="position:fixed;bottom:80px;right:20px;padding:10px 0;z-index:999;display:none;min-width:30%;">
                                                    <div style="display:flex;justify-content:space-between">
                                                        <!--<div style="background-color:#fff;width:100%;padding:5px;display:flex;justify-content:space-between;align-items:center;margin-right:20px;">
                                        <span style="width:60%;display:inline-block;font-size:12px;">  {{$t('TOTAL SYSTEM ORDERS')}}:<b style="font-size:20px;margin-left:10px;">{{totSysOrd}}</b></span>
                                        <span style="width:40%;display:inline-block;font-size:12px;">  {{$t('COMPLETED')}}:<b style="font-size:20px;margin-left:10px;">{{numOrdCom}}</b></span>
                                    </div>-->
                                                        <div style="background-color:#fff;width:100%;padding:5px;display:flex;justify-content:space-between;align-items:center;">
                                                            <div style="display:inline-block;margin-right:10px;cursor:pointer;" @click="stop" v-if="isRun">
                                                                <img style="vertical-align: middle;" src="/img/stop.png" />
                                                            </div>
                                                            <div style="display:inline-block;margin-right:10px;cursor:pointer;" @click="start" v-if="!isRun">
                                                                <img style="vertical-align: middle;" src="/img/run.png" />
                                                            </div>
                                                            <div style="text-align:left;width:calc(100% - 20px);">
                                                                <template>
                                                                    <el-carousel height="28px" direction="vertical" :autoplay="isRun" :interval="10000" arrow="never">
                                                                        <el-carousel-item v-for="(item,index) in mapArr" :key="index">
                                                                            <h3 :title="item.orderInfo" class="medium" style="line-height:28px;font-size:0.7rem;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;width:100%;">{{ item.orderInfo }}</h3>
                                                                        </el-carousel-item>
                                                                    </el-carousel>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="mapTip" style="position:fixed;bottom:25px;right:20px;padding:10px 0;z-index:999;display:none;min-width:50%;background:#fff;">
                                                    <div style="display:flex;align-items:center;justify-content:space-between;padding:0 20px;">
                                                        <div style="margin-right:20px;">
                                                            <img style="width:1.25rem;vertical-align:middle;margin-right:5px;" src="/img/report/Lx_Max.png" />MULTIPLE PROCESSES
                                                        </div>
                                                        <div style="margin-right:20px;">
                                                            <img style="width:1.25rem;vertical-align:middle;margin-right:5px;" src="/img/report/L5_Max.png" />RAW FIBER
                                                        </div>
                                                        <div style="margin-right:20px;">
                                                            <img style="width:1.25rem;vertical-align:middle;margin-right:5px;" src="/img/report/L4_Max.png" />MATERIAL PROCESS
                                                        </div>
                                                        <div style="margin-right:20px;">
                                                            <img style="width:1.25rem;vertical-align:middle;margin-right:5px;" src="/img/report/L3_Max.png" />YARN
                                                        </div>
                                                        <div style="margin-right:20px;">
                                                            <img style="width:1.25rem;vertical-align:middle;margin-right:5px;" src="/img/report/L2_Max.png" />FABRIC
                                                        </div>
                                                        <div>
                                                            <img style="width:1.25rem;vertical-align:middle;margin-right:5px;" src="/img/report/L1_Max.png" />GARMENT
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="map" style="width: 100%;height:100%;"></div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-main>
                            <el-footer style="padding: 0 0px !important; height: auto">
                                <el-row style="width: 100%;">
                                    <el-col :span="10" style="display: flex;padding-right: 20px;height:40px;">
                                        <div style="background-color:#fff;width:100%;padding:5px;display:flex;justify-content:space-between;align-items:center;">
                                            <span style="width:60%;display:inline-block;font-size:12px;">  {{$t('TOTAL SYSTEM ORDERS')}}:<b style="font-size:20px;margin-left:10px;">{{totSysOrd}}</b></span>
                                            <span style="width:40%;display:inline-block;font-size:12px;">  {{$t('COMPLETED')}}:<b style="font-size:20px;margin-left:10px;">{{numOrdCom}}</b></span>
                                        </div>
                                    </el-col>
                                    <el-col :span="14" style="height:40px;">
                                        <!--<div style="background-color:#fff;width:100%;padding:5px;">-->
                                        <div style="background-color:#fff;width:100%;padding:5px;display:flex;justify-content:space-between;align-items:center;">
                                            <div style="display:inline-block;margin-right:10px;cursor:pointer;" @click="stop" v-if="isRun">
                                                <img style="vertical-align: middle;" src="/img/stop.png" />
                                            </div>
                                            <div style="display:inline-block;margin-right:10px;cursor:pointer;" @click="start" v-if="!isRun">
                                                <img style="vertical-align: middle;" src="/img/run.png" />
                                            </div>
                                            <div style="text-align:left;width:calc(100% - 20px);">
                                                <template>
                                                    <el-carousel height="28px" direction="vertical" :autoplay="isRun" :interval="10000" arrow="never">
                                                        <el-carousel-item v-for="(item,index) in mapArr" :key="index">
                                                            <h3 :title="item.orderInfo" class="medium" style="line-height:28px;font-size:0.7rem;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;width:100%;">{{ item.orderInfo }}</h3>
                                                        </el-carousel-item>
                                                    </el-carousel>
                                                </template>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-footer>
                        </el-col>
                    </el-row>
                </el-container>
            </el-container>
        </div>
    </div>
</template>


<style>
    .mapIpt {
        margin-right: 15px;
    }

        .mapIpt input {
            background: transparent;
            border: none;
            border-bottom: 1px solid #ccc;
            color: #fff;
            border-radius: 0;
        }

    #pvhinfo_container {
        padding: 0 1.25rem;
        padding-bottom: 1.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .chartsDiv {
        position: relative;
        border-radius: 0.625rem 0.625rem 0 0;
        height: 15.625rem !important;
        background-color: #fff;
    }

    .chartsHeaderDiv {
        width: 100%;
        display: flex;
        height: 15%;
        padding: 0 0.5rem;
        border-bottom: 1px solid #EBEBEB;
        box-sizing: border-box;
    }

    .chartsBodyDiv {
        width: 100%;
        display: flex;
        height: 85%;
        box-sizing: border-box;
    }

    .chartsMapDiv {
        position: relative;
        border-radius: 0.625rem 0.625rem 0 0;
        height: 460px !important;
        background-color: #fff;
    }

    .chartsHeaderMapDiv {
        width: 100%;
        display: flex;
        height: 7.5%;
        padding: 0 0.5rem;
        border-bottom: 1px solid #EBEBEB;
        box-sizing: border-box;
    }

    .chartsBodyMapDiv {
        width: 100%;
        display: flex;
        height: 90%;
        box-sizing: border-box;
    }

    .headerDiv {
        color: #333;
        text-align: center;
        height: auto !important;
    }

    .asideDiv {
        width: 33.3% !important;
        padding-left: 1.25rem;
        padding-right: 10px;
        color: #333;
        text-align: center;
    }

    .titImg {
        height: 1.3rem;
        margin-right: 0.5rem;
        vertical-align: middle;
    }

    .el-col {
        margin-bottom: 1rem !important;
    }

    .tit {
        font-size: 0.75rem;
    }

    .gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
        background: transparent;
    }

    .gm-style .gm-style-iw-c {
        background-color: rgba(0,0,0,.5);
        color: #fff;
    }

    .gm-style .gm-style-iw-t::after {
        background: linear-gradient(45deg,rgba(0,0,0,.5) 50%,rgba(0,0,0,0) 51%,rgba(0,0,0,0) 100%)
    }
</style>

<script>
    import { mapGetters, mapState } from "vuex";
    import { MarkerClusterer } from "@googlemaps/markerclusterer"
    import { ProcessDropDownList, TracingCharts, TracingDropDownList, TracingStringList } from "@/api/unified";
    import { listenfullscreen } from "@/util/util";

    export default {
        data() {
            return {
                POd: "",
                restaurants: [],
                proCode: "",
                proName: "",
                loading01: true,
                loading02: true,
                loading03: true,
                loading04: true,
                loading05: true,
                yearVal: "2021",
                selectYear: 2021,
                nowDate: '333',
                charts01_brandVal: "",
                charts01_dataVal: "",
                charts02_brandVal: "",
                charts02_dataVal: "",
                charts05_Val: "",
                charts06_dataVal: "",
                beginDate: "",
                endDate: "",
                beginDate01: "",
                endDate01: "",
                beginDate02: "",
                endDate02: "",
                brand02: "",
                type01: "year",
                type02: "year",
                cla05: "",
                totPoNum: 0,
                comNum: 0,
                chartData01: [],
                chartData02: [],
                chartData03: [],
                chartData04: [],
                chartData05: [],
                mapgyxList: [],
                source01: [],
                options: [],
                brandData: [],
                charts05Data: [],
                vendorData: [],
                poData: [],
                productCodeData: [],
                productNameData: [],
                vendorVal: "",
                poDataVal: "",
                productCodeVal: "",
                productNameVal: "",
                typeVal: "FirstQuery",
                timer: "",
                mapDataInfo: [],
                isRun: true,
                newLoadMap: true,
                mapNum: 0,
                dataYears: [{ value: 2021, label: "2021" }],
                dataMonths: [{ value: 0, label: "ALL" }, { value: 1, label: "01" }, { value: 2, label: "02" }, { value: 3, label: "03" },
                { value: 4, label: "04" }, { value: 5, label: "05" }, { value: 6, label: "06" },
                { value: 7, label: "07" }, { value: 8, label: "08" }, { value: 9, label: "09" },
                { value: 10, label: "10" }, { value: 11, label: "11" }, { value: 12, label: "12" },],
                stateData: [
                    {
                        label: this.$t('Delete'),
                        value: 1
                    },
                    {
                        label: this.$t('initial'),
                        value: 0
                    },
                    {
                        label: this.$t('under order'),
                        value: 5
                    }
                    ,
                    {
                        label: this.$t('under declaration'),
                        value: 10
                    },
                    {
                        label: this.$t('declaration locking'),
                        value: 20
                    },
                    {
                        label: this.$t('declaration completed'),
                        value: 90
                    }],
                totSysOrd: 0,
                numOrdCom: 0,
                mapArr: [],
            }
        },
        created() {
            this.intoDate();//先设置查询日期
        },//加载
        mounted() {
            this.init();
            listenfullscreen(this.setS);
            listenfullscreen(this.loadChart06MAP);
        },
        computed: {
            ...mapState({
                showDebug: state => state.common.showDebug,
                showColor: state => state.common.showColor,
                showTheme: state => state.common.showTheme,
                showLock: state => state.common.showLock,
                showFullScren: state => state.common.showFullScren,
                showCollapse: state => state.common.showCollapse,
                showSearch: state => state.common.showSearch,
                showMenu: state => state.common.showMenu
            }),
            ...mapGetters([
                "userInfo",
                "isFullScren",
                "tagWel",
                "tagList",
                "isCollapse",
                "tag",
                "logsLen",
                "logsFlag"
            ])
        },
        methods: {
            init() {
                //分类下拉框
                this.charts05Data = [];
                TracingDropDownList('VendorSectionList').then(res => {
                    this.charts05Data = res.data;
                    this.charts05_Val = res.data[0].value;
                    this.cla05 = res.data[0].value;
                    this.loadChart05();
                });

                this.brandData = [];
                ProcessDropDownList('DictList', 'brand').then(res => {
                    this.brandData = res.data;
                    let newItem = { value: '', label: "ALL" };
                    this.brandData.unshift(newItem);
                });
                //供应商下拉框
                this.vendorData = [];
                TracingDropDownList('L1_VendorList').then(res => {
                    this.vendorData = res.data;
                });
                //PO数据源
                this.poData = [];
                TracingStringList("Po").then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        this.poData.push({
                            value: res.data[i]
                        })
                    }
                })
                //成品编码数据源
                this.productCodeData = [];
                TracingStringList("ProductCode").then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        this.productCodeData.push({
                            value: res.data[i]
                        })
                    }
                })
                //商品名称数据源
                this.productNameData = [];
                TracingStringList("ProductName").then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        this.productNameData.push({
                            value: res.data[i]
                        })
                    }
                })
                //
                this.loadChart01();
                this.loadChart02();
                this.loadChart03();
                this.loadChart04();
                //加载地图
                this.loadChart06MAP();
            },
            intoDate() {
                //默认当前年份
                var nowDt = new Date(); //日期对象
                this.selectYear = nowDt.getFullYear();
                this.yearVal = nowDt.getFullYear().toString();
                this.beginDate = this.selectYear + "-01-01";
                this.endDate = (this.selectYear + 1) + "-01-01";
                this.beginDate01 = this.beginDate;
                this.endDate01 = this.endDate;
                this.beginDate02 = this.beginDate;
                this.endDate02 = this.endDate;
            },
            //图1
            async loadChart01() {
                var chartDom = document.getElementById('charts_01');
                var myChart = this.$echarts.init(chartDom);
                var option;
                TracingCharts('HomePage_chartData01(ORDER_QUANTITY)', this.beginDate01, this.endDate01, this.type01).then(res => {
                    var datainfo = res.data;
                    if (datainfo != null) {
                        this.source01 = [];
                        datainfo.forEach(v => {
                            this.source01.push(v.source);
                        })
                        option = {
                            //调整空白左右上下间距
                            grid: {
                                left: '5%',
                                right: '5%',
                                top: "15%",
                                bottom: '5%',
                                containLabel: true
                            },
                            legend: {
                                top: "0%",
                                type: "scroll",
                                left: "25",
                                itemWidth: 10,
                                itemHeight: 10,
                                textStyle: {
                                    fontSize: 10
                                }
                            },
                            color: ['#3093FF', '#00B7FF', '#FFB820', '#72D1F8', '#15A2FF'],
                            tooltip: {
                                textStyle: {
                                    align: 'left'
                                }
                            },
                            dataset: {
                                source: this.source01
                            },
                            xAxis: {
                                type: 'category'
                            },
                            yAxis: {
                                axisTick: {
                                    show: true
                                },
                                axisLine: {
                                    show: true
                                },
                                splitLine: {
                                    show: false
                                }
                            },
                            series: [
                                { type: 'bar', barMaxWidth: 15, },
                                { type: 'bar', barMaxWidth: 15, },
                                { type: 'bar', barMaxWidth: 15, },
                                { type: 'bar', barMaxWidth: 15, },
                                { type: 'bar', barMaxWidth: 15, }]
                        };
                    }
                    myChart.setOption(option);
                    this.loading01 = false;
                });
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //图2
            async loadChart02() {
                var chartDom = document.getElementById('charts_02');
                var myChart = this.$echarts.init(chartDom);
                var option;
                TracingCharts('HomePage_chartData02(TOTAL_BRAND_ANALYSIS)', this.beginDate02, this.endDate02, this.type02, this.brand02).then(res => {
                    var datainfo = res.data;
                    if (datainfo != null) {
                        option = {
                            //调整空白左右上下间距
                            grid: {
                                left: '5%',
                                right: '5%',
                                top: "5%",
                                bottom: '5%',
                                containLabel: true
                            },
                            tooltip: {
                                trigger: 'axis',
                                textStyle: {
                                    align: 'left'
                                }
                            },
                            xAxis: {
                                type: 'category',
                                data: datainfo[0].labeLarray,
                                boundaryGap: false,
                            },
                            yAxis: {
                                axisTick: {
                                    show: true
                                },
                                axisLine: {
                                    show: true
                                },
                                splitLine: {
                                    show: false
                                },
                                type: 'value',
                            },
                            color: ['#00B7FF'],
                            series: [
                                {
                                    data: datainfo[0].valueArray,
                                    areaStyle: {},
                                    type: 'line',
                                    smooth: true,
                                    connectNulls: true,
                                    areaStyle: {
                                        opacity: 0.8,
                                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            {
                                                offset: 0,
                                                color: 'rgba(160,219,255)'
                                            },
                                            {
                                                offset: 1,
                                                color: 'rgba(255, 255, 255)'
                                            }
                                        ])
                                    }
                                }
                            ]
                        };
                    }
                    myChart.setOption(option);
                    this.loading02 = false;
                });
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //图3待实现
            async loadChart03() {
                var chartDom = document.getElementById('charts_03');
                var myChart = this.$echarts.init(chartDom);
                var option;
                TracingCharts('HomePage_chartData03(WRITE_OFF_QUANTITY_OF_RAW_MATERIAL_ORDER)', this.beginDate02, this.endDate02).then(res => {
                    var datainfo = res.data;
                    if (datainfo != null) {
                        option = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    // Use axis to trigger tooltip
                                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                },
                                textStyle: {
                                    align: 'left'
                                }
                            },
                            legend: {
                                type: "scroll",
                                itemWidth: 10,
                                itemHeight: 10,
                                textStyle: {
                                    fontSize: 10
                                },
                                right: "20"
                            },
                            color: ['#3093FF', '#00B7FF', '#72D1F8', '#B4EAFF'],
                            //调整空白左右上下间距
                            grid: {
                                left: '5%',
                                right: '5%',
                                top: "15%",
                                bottom: '5%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: datainfo[0].labeLarray
                            },
                            yAxis: {
                                type: 'value',
                                axisLine: {
                                    show: true
                                },
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: true
                                },
                            },
                            series: datainfo[0].series
                        };
                    }
                    myChart.setOption(option);
                    this.loading03 = false;
                });
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //图4
            async loadChart04() {
                var chartDom = document.getElementById('charts_04');
                var myChart = this.$echarts.init(chartDom);
                var option;
                TracingCharts('HomePage_chartData04(SUPPLIER_CERTIFICATION_QUANTITY)', this.beginDate, this.endDate).then(res => {
                    var datainfo = res.data;
                    if (datainfo != null) {
                        option = {
                            //调整空白左右上下间距
                            grid: {
                                left: '1%',
                                right: '3%',
                                top: "5%",
                                bottom: '5%',
                                containLabel: true
                            },
                            tooltip: {
                                //trigger: 'item',
                                formatter: '{b0}: {c0}',
                                textStyle: {
                                    fontSize: 8
                                }
                            },
                            //legend: {
                            //    orient: 'vertical',
                            //    left: 'left'
                            //},
                            color: ['#3093FF', '#00B7FF', '#FFB820', '#72D1F8', '#15A2FF'],
                            series: [
                                {
                                    name: 'Access From',
                                    type: 'pie',
                                    radius: 80,
                                    center: ['50%', '50%'],
                                    data: datainfo,
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    },
                                    label: {
                                        fontSize: 8,
                                    }
                                }
                            ]
                        };

                    }
                    this.loading04 = false;
                    myChart.setOption(option);
                });
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //图5
            async loadChart05() {
                var chartDom = document.getElementById('charts_05');
                var myChart = this.$echarts.init(chartDom);
                var option;
                TracingCharts('HomePage_chartData05(RANKING_OF_TOP_TEN_SUPPLIERS_GARMENT)', this.beginDate, this.endDate, this.cla05).then(res => {
                    var datainfo = res.data;
                    if (datainfo != null) {
                        option = {
                            grid: {
                                left: '5%',
                                right: '8%',
                                top: "5%",
                                bottom: '5%',
                                containLabel: true
                            },
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                },
                                textStyle: {
                                    fontSize: 8,
                                    align: "left"
                                }
                            },
                            legend: {},
                            color: ['#00B7FF'],
                            xAxis: {
                                type: 'value',
                                boundaryGap: [0, 0.01],
                                axisTick: {
                                    show: true
                                },
                                axisLine: {
                                    show: true
                                },
                                splitLine: {
                                    show: false
                                },
                            },
                            yAxis: {
                                type: 'category',
                                data: datainfo[0].labeLarray,
                                axisLabel: {
                                    inside: true,
                                    fontSize: 10,
                                    //formatter: function (value) {
                                    //    var str = "";
                                    //    var num = 13; //每行显示字数
                                    //    var valLength = value.length; //该项x轴字数
                                    //    var rowNum = Math.ceil(valLength / num); // 行数

                                    //    if (rowNum > 1) {
                                    //        for (var i = 0; i < rowNum; i++) {
                                    //            var temp = "";
                                    //            var start = i * num;
                                    //            var end = start + num;

                                    //            temp = value.substring(start, end) + "\n";
                                    //            str += temp;
                                    //        }
                                    //        return str;
                                    //    } else {
                                    //        return value;
                                    //    }
                                    //}
                                },
                                z: 10,
                            },
                            series: [
                                {
                                    type: 'bar',
                                    data: datainfo[0].valueArray,
                                    barMaxWidth: 20,
                                },
                            ]
                        };
                    }
                    this.loading05 = false;
                    myChart.setOption(option);
                });
                window.addEventListener("resize", () => { myChart.resize(); });
            },
            //图6 地图
            async loadChart06MAP() {
                var that = this;
                //地图接口
                // typeVal :FirstQuery 第一次请求
                // typeVal :SupplierQuery 查询供应商请求
                // typeVal :FullScreenQuery 全屏界面请求
                let map;
                let sss;
                if (this.isFullScren) {
                    sss = google.maps.MapTypeStyle = [
                        {
                            stylers: [{ visibility: "on" }],
                        },
                    ];
                } else {
                    sss = google.maps.MapTypeStyle = [
                        {
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "landscape",
                            elementType: "geometry",
                            stylers: [{ visibility: "on" }, { color: "#C2C2C4" }],
                        },
                        {
                            featureType: "water",
                            elementType: "geometry",
                            stylers: [{ visibility: "on" }, { color: "#ffffff" }],
                        },
                    ];
                }
                const mapStyle = sss;
                //点击标记的信息框
                const infowindow = new google.maps.InfoWindow();
                function attachSecretMessage(marker, secretMessage) {
                    var con = "";
                    marker.addListener("click", () => {
                        con = "";
                        if (secretMessage.length > 0) {
                            if (secretMessage.length > 1) {
                                for (var o = 0; o < secretMessage.length; o++) {
                                    con += `<div style="margin-bottom:5px;padding-bottom:5px;border-bottom:1px dashed #ddd;">
                                                <p><span style="font-weight:600;margin-right:3px;">`+ that.$t('PO') + `：</span>` + secretMessage[o].Po + `</p>
                                                <p><span style="font-weight:600;margin-right:3px;">`+ that.$t('FACTORY') + `：</span>` + secretMessage[o].Factory + `</p>
                                                <p><span style="font-weight:600;margin-right:3px;">`+ that.$t('DATE') + `：</span>` + secretMessage[o].Date + `</p>
                                                <p><span style="font-weight:600;margin-right:3px;">`+ that.$t('STATE') + `：</span>` + that.$t(secretMessage[o].State) + `</p>
                                            </div>`;
                                }
                            } else {
                                con = `<div>
                                    <p><span style="font-weight:600;margin-right:3px;">`+ that.$t('PO') + `：</span>` + secretMessage[0].Po + `</p>
                                    <p><span style="font-weight:600;margin-right:3px;">`+ that.$t('FACTORY') + `：</span>` + secretMessage[0].Factory + `</p>
                                    <p><span style="font-weight:600;margin-right:3px;">`+ that.$t('DATE') + `：</span>` + secretMessage[0].Date + `</p>
                                    <p><span style="font-weight:600;margin-right:3px;">`+ that.$t('STATE') + `：</span>` + that.$t(secretMessage[0].State) + `</p>
                                </div>`;
                            }
                        } else {
                            con = `<div>
                                    <p>`+ that.$t('Not Data') + `</p>
                                </div>`;
                        }
                        infowindow.close();
                        infowindow.setContent(con);
                        infowindow.open(marker.get("map"), marker);

                    });
                }
                map = new google.maps.Map(document.getElementById("map"), {
                    center: { lat: 22.311264102803946, lng: 114.19436921093741 },
                    zoom: 1,
                    styles: mapStyle,
                    disableDefaultUI: true,
                });
                const lineSymbol = {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                };
                function animateCircle(line) {
                    let count = 0;
                    window.setInterval(() => {
                        count = (count + 1) % 200;
                        const icons = line.get("icons");
                        icons[0].offset = count / 2 + "%";
                        line.set("icons", icons);
                    }, 20);
                }
                var markers = [], line, mapD, lines = [], arrMar;
                function deleteMarkers() {
                    for (let i = 0; i < markers.length; i++) {
                        markers[i].setMap(null);
                    }
                    markers = [];
                }

                function initMap() {
                    var mak, mar;
                    var bounds = new google.maps.LatLngBounds();
                    if (mapD.mapPoint.length > 1) {
                        var path = [];
                        markers = [];
                        for (var i = 0; i < mapD.mapPoint.length; i++) {
                            mar = new google.maps.Marker({
                                position: mapD.mapPoint[i].zb,
                                map,
                                icon: mapD.mapPoint[i].imgURL,
                            });
                            attachSecretMessage(mar, mapD.mapPoint[i].tooltip);
                            //path.push(mar.getPosition());
                            markers.push(mar)
                        }
                        arrMar = new MarkerClusterer({ markers, map });

                        for (var i = 0; i < markers.length; i++) {
                            bounds.extend(markers[i].getPosition());
                        }
                        map.fitBounds(bounds);
                        lines = [];
                        for (var s = 0; s < mapD.mapRoute.length; s++) {
                            path = [];
                            path.push(mapD.mapRoute[s].startPoint)
                            path.push(mapD.mapRoute[s].endPoint)
                            //path.push(arr);
                            line = new google.maps.Polyline({
                                path: path,
                                strokeColor: "#666666",
                                strokeOpacity: 1.0,
                                strokeWeight: 3,
                                geodesic: true,
                                map: map,
                                icons: [
                                    {
                                        icon: lineSymbol,
                                        offset: "50%",
                                    }
                                ]
                            });
                            lines.push(line);
                            animateCircle(line);
                        }

                    } else {
                        mak = new google.maps.Marker({
                            position: mapD.mapPoint[0].zb,
                            map,
                            icon: mapD.mapPoint[0].imgURL
                        });
                        markers.push(mak)
                        attachSecretMessage(mak, mapD.mapPoint[0].tooltip);
                    }
                }
                this.totSysOrd = 0;
                this.numOrdCom = 0;
                this.mapArr = [];
                if (this.newLoadMap) {
                    TracingCharts('HomePage_chartData06_MAP', this.beginDate, this.endDate, this.vendorVal, this.POd, this.proCode, this.proName, this.typeVal).then(res => {
                        that.mapDataInfo = res;
                        if (that.mapDataInfo.data[0].mapInfo.length <= 0) {
                            this.$message({
                                type: "error",
                                message: that.$t('The supplier has no data at present'),
                            });
                        } else {
                            mapD = this.mapDataInfo.data[0].mapInfo[that.mapNum];
                            initMap();
                            this.timer = setInterval(function () {
                                that.mapNum++;
                                if (that.mapNum > that.mapDataInfo.data[0].mapInfo.length - 1) {
                                    that.mapNum = 0;
                                }
                                mapD = that.mapDataInfo.data[0].mapInfo[that.mapNum];
                                deleteMarkers();
                                if (lines.length > 0) {
                                    for (var p = 0; p < lines.length; p++) {
                                        lines[p].setMap(null);
                                    }
                                }
                                if (arrMar !== undefined) {
                                    arrMar.setMap(null);
                                }
                                initMap();
                            }, 10000)
                            this.totSysOrd = this.mapDataInfo.data[0].intLabel;
                            this.numOrdCom = this.mapDataInfo.data[0].intValue;
                            this.mapArr = this.mapDataInfo.data[0].mapInfo;
                        }
                    });
                } else {
                    if (this.mapDataInfo!=null) {
                        if (this.mapDataInfo.data[0].mapInfo.length <= 0) {
                            this.$message({
                                type: "error",
                                message: that.$t('The supplier has no data at present'),
                            });
                        }
                        else {

                            mapD = this.mapDataInfo.data[0].mapInfo[that.mapNum];
                            initMap();
                            this.timer = setInterval(function () {
                                that.mapNum++;
                                if (that.mapNum > that.mapDataInfo.data[0].mapInfo.length - 1) {
                                    that.mapNum = 0;
                                }
                                mapD = that.mapDataInfo.data[0].mapInfo[that.mapNum];
                                deleteMarkers();
                                if (lines.length > 0) {
                                    for (var p = 0; p < lines.length; p++) {
                                        lines[p].setMap(null);
                                    }
                                }
                                if (arrMar !== undefined) {
                                    arrMar.setMap(null);
                                }
                                initMap();
                            }, 10000)
                            this.totSysOrd = this.mapDataInfo.data[0].intLabel;
                            this.numOrdCom = this.mapDataInfo.data[0].intValue;
                            this.mapArr = this.mapDataInfo.data[0].mapInfo;
                        }
                    }
                }
            },
            start() {
                this.isRun = true;
                this.newLoadMap = false;
                this.loadChart06MAP();
            },
            stop() {
                this.isRun = false;
                clearInterval(this.timer);
            },
            // 年选择
            yearselectChanged(value) {
                var queryDt = new Date(value); //日期对象
                var nowDt = new Date(); //日期对象
                var queryYear = queryDt.getFullYear();
                var nowYear = nowDt.getFullYear();
                //超过当前时间不加载
                if (queryYear > nowYear || queryYear < 2019) {
                    this.$message({
                        message: this.$t('yearNoData'),
                        type: "error"
                    });//提示无数据
                    return false;
                }
                else {
                    this.selectYear = queryYear
                    this.beginDate = this.selectYear + "-01-01";
                    this.endDate = (this.selectYear + 1) + "-01-01";
                    this.init();
                }
            },
            //图一月选择
            data1selectChanged(value) {
                if (value != 0) {
                    this.type01 = "month";
                    this.beginDate01 = this.selectYear + "-" + value + "-01";
                    if (value == 12) {
                        var year_i = this.selectYear + 1;
                        this.endDate01 = year_i + "-01-01";
                    } else {
                        this.endDate01 = this.selectYear + "-" + (value + 1) + "-01";
                    }
                }
                else {
                    this.type01 = "year";
                    this.beginDate01 = this.beginDate;
                    this.endDate01 = this.endDate;
                }
                this.loadChart01();
            },
            //图二品牌选择
            brandselectChanged(value) {
                this.brand02 = value;
                this.loadChart02();
            },
            //图二月选择
            data2selectChanged(value) {
                if (value != 0) {
                    this.type02 = "month";
                    this.beginDate02 = this.selectYear + "-" + value + "-01";
                    if (value == 12) {
                        var year_i = this.selectYear + 1;
                        this.endDate02 = year_i + "-01-01";
                    } else {
                        this.endDate02 = this.selectYear + "-" + (value + 1) + "-01";
                    }
                }
                else {
                    this.type02 = "year";
                    this.beginDate02 = this.beginDate;
                    this.endDate02 = this.endDate;
                }
                this.loadChart02();
            },
            //图5分类选择
            charts05selectChanged(v) {
                this.cla05 = v;
                this.loadChart05();
            },
            //地图供应商选择
            mapselectChanged(v) {
                this.POd = "";
                this.proCode = "";
                this.proName = "";
                this.typeVal = "SupplierQuery";
                this.isRun = true;
                this.newLoadMap = true;
                this.mapNum = 0;
                this.loadChart06MAP();
            },
            //地图全屏
            bigScr() {
                this.newLoadMap = false;
                let element = document.getElementById('mapDiv');//设置后就是   id==con_lf_top_div 的容器全屏
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                }

            },
            poSearch(queryString, cb) {
                var restaurants = this.poData;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results.slice(0,10));
            },
            pcSearch(queryString, cb) {
                var restaurants = this.productCodeData;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results.slice(0,10));
            },
            pnSearch(queryString, cb) {
                var restaurants = this.productNameData;
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results.slice(0,10));
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            mapScSear() {
                this.vendorVal = "";
                this.typeVal = "FullScreenQuery";
                this.isRun = true;
                this.newLoadMap = true;
                this.mapNum = 0;
                this.loadChart06MAP();
            },
            setS() {
                if (!this.isFullScren) {
                    this.POd = "";
                    this.proCode = "";
                    this.proName = "";
                    document.querySelector("#mapTopSear").style.display = "none";
                    document.querySelector("#mapTwo").style.display = "none";
                    document.querySelector("#mapScrTot").style.display = "none";
                    document.querySelector("#mapTip").style.display = "none";
                } else {
                    if (document.querySelector("#mapDiv").clientWidth === document.body.clientWidth) {
                        document.querySelector("#mapTopSear").style.display = "block";
                        document.querySelector("#mapTwo").style.display = "block";
                        document.querySelector("#mapScrTot").style.display = "block";
                        document.querySelector("#mapTip").style.display = "block";
                    }
                }
            }
        }
    }
</script>